import $ from 'jquery'
import Utils from "../../modules/utils"

const getVendorOrder = (id) => {
  Utils.get('vendor_orders/' + id, (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to get the vendor order.')
    } else {
      setToWindowDot(res.data)
      sendLoadEditVpoEvent()
    }
  })
}

const setToWindowDot = (data) => {
  let {vpo, styleNumbers, userEmail, logs} = data
  window.editVpoPage = {}
  window.editVpoPage.logs = logs
  window.editVpoPage.orderSummary = {factory: vpo.factory, createdAt: vpo.createdAt, userEmail, status: vpo.status, edited: vpo.edited, editedOn: vpo.editedOn}
  window.editVpoPage.selectBuyer = {buyer: vpo.buyer, reorder: vpo.reorder, ponumber: vpo.ponumber, salesRepId: vpo.salesRepId, accountId: vpo.accountId, styleNumbers}
  $('#style-number-textarea').text(styleNumbers.join("\n"))

  let quantity = {}, size = {}, sizeRatio = {}, cost = {}, msrp = {}, qtyPerPolibag = {},
    exFactoryDate = {}, cancelDate = {}, delivDate = {},
    fabrication = {}, factory = {}, loadingDate = {}, fobRtsDate = {},
    containerNumber = {}, comment = {}, shippingClass = {}, tickets = {},
    asnxFactoryDate = {}, at = {}, country = {}, casePack = {}, cponumber = {},
    discount = {}, fabricWeight = {}, fabricContent = {}, currency = {}

  vpo.cadIds.forEach((cadId, index) => {
    quantity[cadId] = JSON.parse(vpo.quantityMultiple[index])
    size[cadId] = JSON.parse(vpo.size[index])
    sizeRatio[cadId] = JSON.parse(vpo.sizeRatio[index])
    cost[cadId] = vpo.price[index]
    msrp[cadId] = vpo.msrp[index]
    qtyPerPolibag[cadId] = vpo.qtyPerPolibag[index]
    exFactoryDate[cadId] = vpo.exFactory[index]
    asnxFactoryDate[cadId] = vpo.asnxFactory[index]
    cancelDate[cadId] = vpo.cancel[index]
    delivDate[cadId] = vpo.deliv[index]
    fabrication[cadId] = vpo.fabrication[index]
    factory[cadId] = vpo.factory
    loadingDate[cadId] = vpo.loading[index]
    fobRtsDate[cadId] = vpo.fobRts[index]
    containerNumber[cadId] = vpo.containerNumber[index]
    comment[cadId] = vpo.comments[index]
    shippingClass[cadId] = vpo.shippingClass[index]
    tickets[cadId] = vpo.tickets[index]
    at[cadId] = vpo.at[index]
    country[cadId] = vpo.country
    casePack[cadId] = vpo.casePack[index]
    cponumber[cadId] = vpo.cponumber
    discount[cadId] = vpo.discount
    fabricWeight[cadId] = vpo.fabricWeight
    fabricContent[cadId] = vpo.fabricContent
    currency[cadId] = vpo.currency
  })

  window.newVpoPage.cadDetailsTable = {
    quantity, size, sizeRatio, cost, msrp, qtyPerPolibag,
    exFactoryDate, cancelDate, delivDate,
    fabrication, factory, loadingDate, fobRtsDate,
    containerNumber, comment, shippingClass, tickets,
    asnxFactoryDate, at, country, casePack, cponumber,
    discount, fabricWeight, fabricContent, currency
  }
}

const sendLoadEditVpoEvent = () => {
  window.dispatchEvent(new Event('load-edit-vpo-data'))
}

export {getVendorOrder}
