const factoryNames = {
 "BZ43": "060 TTEX KNITWEAR LTD",
 "BZ59": "071 PROMODA TEXTILES LTD. MENS",
 "BZ74": "084 SHAHADA KNITTING",
 "BZ76": "086 / STITCH TONE APPARELS LTD.",
 "BZ77": "087 - JNZ - MAMUN ANS APPAREL BANGLADESH",
 "BZ91": "098 CLIFTON APPARELS LTD",
 "CC96": "1141 CAROL RMB",
 "CH92": "099 - IVY - NEW FASHION FOOTWEAR CO., LIM",
 "CR50": "065 YF SHAPWEAR FTY RMB",
 "CS38": "055 CL",
 "CS48": "064 George HH RMB",
 "CS55": "068 SY (SINGYU) FACTORY/ WARREN",
 "CS61": "073 LONGFAHE - JUTEX",
 // "CS67": "079 / WP Nanchang Weipan Industrial RMB",
 "CS70": "081 / LUCKY ZONE DEVELOPMENT LIMITED",
 "CS71": "082 / WUXI  XINHEXIN  INTERNATIONAL TRADE",
 "CS78": "089 - WINNIE - CHANGZHENG RMB",
 "CS82": "091 PASSION TRADE CO.,LTD MICHELLE",
 "CS83": "092 - ZHEJIANG DAOQIN TEXTILE CO., LTD",
 "CS87": "095 - KERSON (CHINATEX) TEXTILE",
 "CZ01": "006/XYMR HUNG/SHANTOU XINYUAN KNITTING CO",
 "CZ03": "011/HW-HON WEI",
 "CZ04": "012 BQ FACTORY",
 "CZ05": "013-pz",
 "CZ07": "015 GQ FACTORY",
 "CZ08": "016/YDQ FACTORY",
 "CZ10": "018/ XXS FACTORY",
 "CZ11": "019/RX/Runxing factory",
 "CZ12": "020/RLL/RUNLILAI KNITTING UNDERWEAR",
 "CZ13": "021/YZD FACTORY",
 "CZ14": "023/ Rong Wu",
 "CZ15": "025 JXD FACTORY",
 "CZ16": "026 CM RMB",
 "CZ18": "029-YXT",
 "CZ19": "033-YYF",
 "CZ20": "034 YJN FACTORY RMB",
 "CZ22": "035 spring factory",
 "CZ23": "036 YXT - SUSAN",
 "CZ24": "040-DS",
 "CZ25": "041 PS SHENZEN WENJIN CO.,LTD (AMI)",
 "CZ26": "042 ALVA RMB",
 "CZ28": "043 YSM RMB",
 "CZ30": "050 RB SEAMLESS FACTORY RMB",
 "CZ32": "051 HC SEAMLESS FTY",
 "CZ33": "052 JH (CAVIN) RMB",
 "CZ35": "053 HJS ZERO",
 "CZ36": "054 MDX RMB",
 "CZ39": "056 EVA  AROMA INDUSTRIAL LTD",
 "CZ40": "057  PING Fujian Dongyu import & export",
 "CZ41": "058 EZK Fashion",
 "CZ44": "061 JCF/ VIVIAN",
 "CZ45": "062 XY-1/JANNY",
 "CZ46": "063 MANNA SEAMLESS FTY RMB",
 "CZ52": "066 AC TEX",
 "CZ56": "069 LALI KIDS FJTY TY-1",
 "CZ57": "070 QD QIANDAN RMB",
 "CZ60": "072 YXT-3 LASER CUT FACTORY RMB",
 "CZ63": "075 JH-2 JINHENG FACTORY",
 "CZ65": "077 XGY RMB",
 "CZ66": "078 mantn FTY",
 "CZ69": "080 KAQIZI UNDERWEAR FTY (CINDY)",
 "CZ72": "083 YIWU INSHINE LYNN RMB",
 "CZ80": "090 DONGYANG ZHENNIU (ASHLEY SEAMLESS)",
 "CZ85": "093 SHANTOU XINGLIANCHENG TEXTILE BRENDA",
 "CZ86": "094 LEO/SALLY SHANTOU  WANSHIFA",
 "CZ89": "096 SHANTOU BE UNDERWEAR RMB",
 "CZ90": "097 Rongli Garment Co, ltd",
 "CZ94": "SHAWN/GUANGDONG  LANTIANCHENG INDUSTRIAL",
 "CZ95": "HH FACTORY RMB",
 "CZ96": "1153 BR/ZM ZENG MEI KNITTING FACTORY",
 "IA42": "059 SPA EXPORTS",
 "IS75": "085 / Pacific Trade Links",
 "IS96": "Rashi Wears Pvt Ltd",
 "IZ62": "073 MFUNE OVERSEAS ANIL MENS",
 "IZ64": "076 BRIT FASHIONS",
 "FA01": "New Comer Ind. Co. Ltd.",
 "FA02": "1004 - Dongyang Tiantianle Bbay products",
 "FA03": "074 LEO FACTORY RMB",
 "FA05": "1075 CASSIE SHAO / QINGDAO",
 "FA06": "1006 - Qingdao Shine Wink Industial Tradi",
 "FA07": "1001-SUZHOU MEGATEX I/E CO.,LTD",
 "FA08": "1110 HAVING LIMITED",
 "FA09": "1003 - SHANTOU XIRONG UNDERWEAR INDUSTRY",
 "FA10": "GREAT (HONGKONG) Limited",
 "FA11": "1005 - Amrit Exports Pvt.Ltd",
 "FA12": "A & H",
 "FA13": "1002 - STANTEX GROUP LIMITED",
 "FA14": "NINGBO HARVEST INT'L TRADE CO. LTD",
 "FB01": "Siarmor inc and quadsil inc",
 "FB02": "1176 XIN YUAN LTD.",
 "FB03": "088 ALFA KNIT FASHION LTD.",
 "FB04": "Mr June",
 "FB05": "INTERNATIONAL TOUCH COSOLIDATER",
 "FB06": "010/8032 new factoryFENGHUATING RMB",
 "FB07": "tinyue RMB",
 "FB08": "003/JOSI/CHUANGJIANA LINGERIE FACTORY RMB",
 "FB09": "1094 JH SEAMLESS FRY JOJO RMB",
 "FB10": "IDEAL CLOTHING INDUSTRY LIMITED RMB",
 "FB11": "IHL China Team RMB",
 "FB12": "JXY RMB",
 "FC01": "087 MAMUN ANS APPAREL BANGLADESH",
 "FC02": "096 SHANTOU BE UNDERWEAR",
 "FC03": "1007 - RP Textile & Fashion",
 "FC04": "1008 LXI SEAMLESS (SHAWN)",
 "FC05": "1008 LXI SEAMLESS (SHAWN) RMB",
 "FC06": "1009 BEST FIT FASHION LIMITED",
 "FC07": "1010 Ruixiang Hongkong Trade Limited",
 "FC08": "1011 NANCHANG JIUSONG IMPORT AND EXPORT C",
 "FC09": "1012 Changtai Garment Knitting Co., Ltd",
 "FC10": "1013 MONICA GUANGDONG INDUSTRIAL CO. RMB",
 "FC11": "1014 - Jinxianglong underwear factory",
 "FC12": "1016 LKK LOVING KISS KNITTING CO LTD",
 "FC13": "1017 N.S Sourcing.",
 "FC14": "1018 HONJI UNDERWEAR CO.,LTD",
 "FC15": "1019 ZHEJIANG QIDUOZI DRESS CO,.LTD",
 "FC16": "1020 YI TRADING CO.,LIMITED",
 "FC17": "1021 SHENZHEN HANHAI LONGJIN INDUSTRI",
 "FC18": "1022 Xin Meng International Footwear CO.,",
 "FC19": "1024 SHANTOU GUANGZHIYUAN KNITTING CO LTD",
 "FC20": "1025 SHANTOU BAIMU COLTHING INDUSTRY CO.,",
 "FC21": "1026 GANGLIANG INDUSTRY CO.,LTD",
 "FC22": "1027 SHANGHAI FOREIGN TRADE ENTERPRISES P",
 "FC23": "1028 - Hexingsheng Apparel.Co.Limited",
 "FC24": "1029 SHANGHAI YINGQIAO LIMITED", // formerly "Ven Bridge Co.,Ltd",
 "FC25": "1030 Clothing Partner Ltd,",
 "FC26": "1031 A&M global sourcing Ltd.",
 "FC27": "1032 Haining Intex Imp. & Exp. Co., Ltd",
 "FC28": "1033 SHANTOU CITY  CHENGXIN KNITTED UNDER",
 "FC29": "1036 Shenzhen Chaohongtai Technology Co.,",
 "FC30": "1038 SSI SEAMLESS RMB (STANLEY)",
 "FC31": "1039 Shenzhen Ronghuaguang Trading Co., L",
 "FC32": "1039 Shenzhen Ronghuaguang Trading RMB",
 "FC33": "1040 JNZ FASHION LTD BANGLADESH",
 "FC34": "1041 - SHANTOUS QUANSHENG INDUSTRIAL",
 "FC35": "Dedola Global Logistics",
 "FC36": "1094 JH SEAMLESS FRY JOJO USD",
 "FC37": "Maxwide Logistics Inc.",
 "FC38": "1140 SUZHOU HENGRUN IMP. AND EXP. CORP",
 "FC39": "1007 RP COTTON FIELD (BD) LTD CFL",
 "FC40": "1007 RP DIRECT SPORTS LEISUREWEAR DSL",
 "FC41": "6IXTY 8IGHT",
 "FC42": "1007 RP MONDOL FABRICS LIMITED MFL",
 "FC43": "1007 RP CREATIONS & APPARELS LTD-RPCAL",
 "FC44": "1007 RP TEMAKAW FASHION LTD TFL",
 "FC45": "BEST BAGEL COFFEE",
 "FC46": "007 - LTC GUANGDONG  LANTIANCHENG INDU",
 "FC47": "1042 - SHAOXING BELE TEXTILE CO.,LTD.",
 "FC48": "1043 - YESH INTERNATIONAL CO.,LIMITED",
 "FC49": "1007 RP ALIENS TEXWEAR LTD.  ATL",
 "FC50": "007 - LTC GUANGDONG  LANTIANCHENG RMB",
 "FC51": "1007 RP ASKO KNITWEARS LTD AKL",
 "FC52": "1045 - YIWU FORTHBLOOM CO., LTD",
 "FC53": "1046 - ATI- MAGICWORKS LTD",
 "FC54": "1047 NANCHANG CHUANGLONG TEXTILE CO. LTD",
 "FC55": "1049 NANCHANG KONG YEUNG APPAREL CO., LTD",
 "FC56": "067 (RICHESWIN) SHENZHEN HUILI APPAREL CO",
 "FC57": "067 (RICHESWIN) SHENZHEN HUILI APPAR RMB",
 "FC58": "FUZHOU NHH IMPORT AND EXPORT TRADING CO",
 "FC59": "1044 - HING NGAI LEATHERWARE FACTORY LIMI",
 "FC60": "1056 - Riparo LLC",
 "FC61": "1084 CHDC GROUP CO.,LIMITED",
 "FC62": "1058 PROFIT CULTURAL & CREATIVE GROUP CORPORATION",
 "FC63": "1054 ANHUI FANGXI",
 "FC64": "087 MAMUN BAN FASHION INTERNATIONAL",
 "FC65": "1051 - SHANTOU CITY PENGJIA KNITTING INDU",
 "FC66": "1057 HANGZHOU MATCHPOINT",
 "FC67": "1055 RF Shanghai Rosefinch Trading Co.Ltd",
 "FC68": "087 MAMUN - MAM APPARELS & TEXTILE LTD",
 "FC69": "1053 ALESHARAH FASHION APPAREL LTD",
 "FC70": "1050 MAM APPAREL",
 "FC71": "1082 PERFECT ACCESSORY",
 "FC72": "1051 - SHANTOU CITY PENGJIA KNITTING RMB",
 "FC73": "1060 JOES INTERNATIONAL FOOTWEAR COMPANY",
 "FC74": "006 XY RMB",
 "FC75": "079 / WP Nanchang Weipan Industrial Co.Lt",
 "FC76": "070 QD QIANDAN",
 "FC77": "HH FACTORY",
 "FC78": "063 MANNA SEAMLESS FTY",
 "FC79": "083 YIWU INSHINE LYNN",
 "FC80": "052 JH (CAVIN)",
 "FC81": "1141 CAROL USD",
 "FC82": "050 RB SEAMLESS FACTORY",
 "FC83": "WP TEXTILE ENTERPRISES INC",
 "FC84": "EASTERN BUYING GROUP INC",
 "FC85": "IBG IMPORTS INC",
 "FC86": "1061 ANHUI HENGZHICHENG INTERNATIONAL TRADE CO LTD",
 "FC87": "1062 Jiangsu Guotai Int'l Group Guomao Co",
 "FC88": "JIE RUI FENG LEATHER CO.,LTD",
 "FC89": "1086 BHARAT ENTERPRISES",
 "FC90": "1079 JIAXING HEYING IMPORT AND EXPORT CO.,LTD",
 "FC91": "Hisoa (Shanghai) International Trading Co., Ltd.",
 "FC92": "Xiamen C&D Enterprise Co., Ltd.",
 "FC93": "Leming Knitting Co., Ltd.",
 "FC94": "1069 CHONGLAI IMPORT AND EXPORT CO., LTD.",
 "FC95": "1104 FAR FARER INT. TRADE CO., LTD",
 "FC96": "GUANGDONG SHISHENG CLOTHING CO.,LTD",
 "FC97": "1068 SXLH Shaoxing Longhui Trading Co.,Lt",

  "FD01": "054 MDX",
  "FD02": "099 - IVY - NEW FASHION FOOTWEAR CO - RMB",
  "FD03": "1003 - SHANTOU XIRONG UNDERWEAR RMB",
  "FD04": "1010 Ruixiang Hongkong Trade RMB",
  "FD05": "1018 HONJI UNDERWEAR CO.,LTD RMB",
  "FD06": "1021 SHENZHEN HANHAI LONGJIN INDUSTRI RMB",
  "FD07": "1033 SHANTOU CITY  CHENGXIN KNITTED  RMB",
  "FD08": "1034 ENLINNA UNDERWEAR FACTORY",
  "FD09": "1036 Shenzhen Chaohongtai Technology RMB",
  "FD10": "1059 All Star Group Limited",
  "FD11": "1061 GREAT UNION CORP TAIWAN (AGENT)",
  "FD12": "1062 Jiangsu Guotai Int'l Group Guomao Co",
  "FD13": "1063 THE COTTON CLASSIC APPARELSS",
  "FD14": "1064 GUANGZHOU YAFANG TEXTILE CO., LTD",
  "FD15": "1065 DEEN FASHION LTD BANGLADESH",
  "FD16": "1066 Wells Group Apparel Inc",
  "FD17": "1067 SHANTOU PENGJUN KNITTED INDUSTRY CO,",
  "FD18": "1117 ACUMEN VIGOR LIMITED",
  "FD19":  "1110 HAVING LIMITED RMB",
  "FD20": "Hisoa (Shanghai) International Trading Co",
  "FD21": "New Comer Ind. Co. Ltd. RMB",

  "FD22": "S.K.R ATTIRE LIMITED",
  "FD23": "1103 Sincere Garments Co., Ltd.",
  "FD24": "1085 WUXI SUNSHINE TEXTILE SCIENCE TECH",
  "FD25": "1087 Tangerine Skies Pvt. Ltd.",
  "FD26": "1101 Alpine Apparel",
  "FD27": "1096 Vela",
  "FD28": "1088 Soki Bag",
  "FD29": "1118 Zhejiang C Stage Impt & Expt Co. LTD",
  "FD30": "1114 Qingdao Kingworld Int Trade Co.",
  "FD31": "1089 Nanjing jumei garment co ., ltd",
  "FD32": "1083 Zhangjiagang Unitex Co.,Ltd",
  "FD33": "1071 DONGGUAN U WORK INDUSTRY CO (CANDY)",
  "FD34": "1073 WFT",
  "FD35": "1074 : SHANTOU TELIGE APPAREL CO., LTD",
  "FD36": "1038 SSI SEAMLESS (STANLEY)",
  "FD37": "1077 LIXIN APPAREL CO., LIMITED",
  "FD38": "1080 Jiangxi Shunzhe Trading Co.,Ltd",
  "FD39": "1090 SHAOXING CITY SILVERSILK GRMTS, LTD", //duplicate!!! remove
  "FD40": "1109 Wuhan Dawn Investments Co.,Ltd.",
  "FD41": "1078 JINJIANG SHATONG INT. TRADE RMB",
  "FD51": "1078 JINJIANG SHATONG INT TRADE CO USD",
  "FD42": "1076 PENGLIANG UNDERWEAR KNITTING RMB",
  "FD43": "JIANGSU GUOTAI INTERNATIONAL GROUP GUOMAO CO., LTD.",
  "FD44": "1102 NINGBO KEYAR CO., LTD.",
  "FD45": "1099 Suzhou Yunzhixuan Trading Co., LDO",
  "FD46": "1091 Anhui Heading Footwear Co., Ltd",
  "FD47": "1090 SHAOXING CITY SILVERSILK GRMTS, LTD",
  "FD48": "1098 Quanzhou YOYA Trading Co.,Ltd",
  "FD49": "1095 JOC GREAT WALL CORP",
  "FD50": "1092 SHINY FASHION CO.LIMITED",
  "FD52": "1097 SHENZHEN GAOHONG SPTG GOODS CO., LTD",
  "FD53": "1081 SHAOXING CHENFAN CLOTHING TECHN CO.,",
  "FD54": "1100 HS Trade ( Henan ) CO.,LTD",
  "FD55": "HEZE KOKO GREAT GARMENT CO.,LTD",
  "FD56": "1106 Qingdao Hanxinran Textile co.,ltd",
  "FD57": "Zibo Jingshang Hometex Co .,ltd",
  "FD58": "Quanzhou YOYA Trading CCo., Ltd",
  "FD59": "DETAIL FASHION LTD.",
  "FD60": "QINGDAO TOWIN INTERNATIONAL TRADING CO.,LTD",
  "FD61": "1093 NANCHANG DONGSHEN KNITTED GARMENT CO.,LTD",
  "FD62": "Jiangxi Ruizhi Garments I/E Co., Ltd.",
  "FD63": "1107 XIAMEN GOLDEN HUANAN IMP. & EXP. CO.",
  "FD64": "Qingdao Xinweite Garments Co. Ltd.,",
  "ZM01": "1105 Shantou city Chaoyang district coppe",
  "HK01": "1144 SHAN TOU HONGGUI TEXTILE CO.LTD",
  "DH01": "1116 SHANTOU DONGHONG KNITTING IND. CO.,",
  "FD65": "1120 Jinjiang Lengo Garment Co.,ltd",
  "FD66": "1111 Yiwu Qimo Clothing",
  "FD67": "1132 SHANTOU KAI'U KNITTING IND. CO.,LTD",
  "FD68": "1122 TONGYI KNIT DRESS CO., LTD JINJIANG",
  "FD69": "1121 JINJIANG RUXING GMNTS & WEAVING CO.",
  "FD70": "1147 SHANGHAI DAYU INT. TRADING CO LT",
  "FD71": "1112 Nanchang LongSheng Indt Co., LTD",
  "FD72": "1142 SHANGHAI YINGQIAO CO.,LTD",
  "FD73": "Qingdao Xinweite Textiles Co., Ltd",
  "FD74": "1145 JIAXING SEEFINETEX TRADING CO.,LTD.",
  "FD75": "1113 NEW RAINBOW GARMENT CO., Ltd",
  "FD76": "1108 YIWU ZY CLOTHING",
  "FD77": "1105 Shantou city Chaoyang district RMB",
  "FD78": "1129 WEIHAI HONESTY GARMENT CO.,LTD",
  "FD79": "1123 JIAXING NEWBRIDGE IMP.&EXP CO.,LTD.",
  "FD80": "1127 SUMEC TEXT & LIGHT INDUSTRY CO.,LTD",
  "FD81": "1115 JIANGSU BINSTYLE IMP & EXP  CO., LTD",
  "FD82": "1136 BAIJIA(FUJIAN) UNDERWEAR CO.,LTD",
  "FD83": "1131 NINGBO UMAX IMP&EXP.CO.,LTD",
  "FD84": "1013 MONICA GUANGDONG INDUSTRIAL CO.,LTD",
  "FD85": "1182 B&G INTERNATIONAL (FAR EAST) LIMITED",
  "FD86": "1128 WAN MAN ZHU CLOTHING CO., LTD",
  "FD87": "1124 Haute Affaires USA, NYC",
  "FD88": "1033 SHANTOU CITY CHENGXIN KNITTED RMB",
  "FD89": "1138 KAIXI FASHION (HK) CO.,LTD",
  "FD90": "1151 Shantou City Xingliancheng Text Co.",
  "FD91": "1130 Qijia Fashion co. Limited",
  "FD92": "1134 Ningbo Wenxiang Impt & Expt Co., Lt",
  "FD93": "Ningbo Star Shiny Co.,Ltd",
  "FD94": "1148 Quanzhou LempChina bag manufacturer",
  "FD95": "1119 Wisdom Metalworks Co., Ltd.",
  "FD96": "1152 Xiamen Justwin Impt & Expt co., Ltd",
  "FD97": "1137 JIANGSU SOHO TECH TRADING CO.LTD.",
  "FD98": "1149 QINGDAO FANTEX CLOTHING CO., LTD.",
  "FD99": "1125 JIANGXI XUNRUI GARMENT CO., LTD.",
  "FE01": "1143 Nanchang Novae Industrial Co., Ltd.",
  "FE02": "GOLDEN METRO GARMENTS LIMITED",
  "FE03": "1135 Weihai Lianqiao Int Coop. Group",
  "FE04": "1133 Superior Garment Factory",
  "FE05": "1146 FLEXELLE APPAREL TRADING CO., LTD",
  "FE06": "1150 Zhejiang Springair Sunshine Gmts Co.",
  "FE07": "1139 Guangzhou Begol Holdings Ltd.",
  "FE08": "1162 Shantou Xiesheng Apparel Co. Ltd.",
  "FE09": "1159 NANTONG YUANYE IMPT&EXPT TRADING CO",
  "FE10": "1034 ENLINNA UNDERWEAR FACTORY RMB",
  "FE11": "1154 Ealytex Global Inc.",
  "FE12": "1160 Henan S&C Fashion Co., ltd",
  "FE13": "1158 ZHEJIANG SHANQI CLOTHES CO.,LTD",
  "FE14": "1168 HOSOAR (H.K) LIMITED",
  "FE15": "SHANTOU HUICHEN FASHION CO, LTD",
  "FE16": "1157 Polytex Co.,Ltd.Zhejiang",
  "FE17": "1077 LIXIN APPAREL CO., LIMITED RMB",
  "FE18": "1161 JIANGSU HOLLY EVERLASTING INC.",
  "FE19": "1169 SHANGHAI SHUOXIN INTL CO.,LTD",
  "FE20": "1173 XIAMEN KING YOUNG IMP. AND EXP.CO.,LTD.",
  "FE21": "1171 SHAOXING QINGBANG GARMENTS CO.,LTD",
  "FE22": "1177 Wuxi Zhenyu Int, Trade Co.",
  "FE23": "1164 GS GLOBAL SOURCING CO., LTD",
  "FE24": "1155 GU Zhuji Horizon impt & expt co. LTD",
  "FE25": "1156 GU WEIHAI DASHUN GARMENT CO., LTD.",
  "FE26": "1061 GU Jiangxi Starling garment",
  "FE27": "1163 Shenzhen Richsun Textile Co.,Ltd",
  "FE28": "1183 Henan Duoxuan Co.",
  "FE29": "1181 QINGDAO HAOHAO GARMENT CO.LTD",
  "FE30": "1150 Zhejiang Springair Sun Gmts Co. RMB",
  "FE31": "1165 BAI SHENG APPAREL LTD.",
  "FE32": "1172 FUJIAN SOPHIA LEE HOLDINGS CO., LTD.",
  "FE33": "1180 NYSE NY SENSE INC",
  "FE34": "093 SHANTOU XINGLIANCHENG TEXT BRENDA RMB",
  "FF01": "1178 GU WEIHAI DINGXIANG IMP&EXP { Agent}",
  "FF02": "1167 Yantai Pacific Home Fashion Co., Ltd.",
  "FF03": "1166 Shaoxing Shangyu Frank Textile Co.,",
  //DUPLICATE
  // "FF04": "SHANGHAI SHUOXIN INTERNATIONAL CO.,LTD",
  "FF05": "1170 Guangzhou Madison Bag Co.,Ltd",
  "FF06": "1174 GTHS",
  "FF07": "REGENT APPAREL LIMITED",
  "FF08": "1190 DALIAN HONGHU GARMENT TRADE CO.LTD",
  "FF09": "HONGKONG SOLAMODA INDUSTRY CO.,LTD",
  "FF10": "1185 HANXIN",
  "FF11": "1186 Shantou Yuqianfang Knitting Ind Co",
  "FF12": "1179 USUSA APPAREL IMPORT INC",
  "FF13": "1187 Shantou TJ commerce text ind, co",
  "FF14": "1184 Hangzhou Hongting Garment Co., Ltd",
  "FF15": "1191 Nanchang LeJia Clothing Garment Co., Ltd",
  "FF16": "Naz apparel LLC",
  "FF17": "1189 HONG KONG SOLAMODA INDUSTRY CO. LTD.",
  "FF18": "1188 Jiangxi Xindu Woolen Knit Co., Ltd.",
  "FF19": "1192 ANHUI HOPE IMP.& EXP. CO. LTD",
  "FF20": "YIWU SURE IMP&EXP CO.,LTD",
  "FF21": "WEIHAI MODA EXPRESS CO., LTD.",
}

const factories = [
  'PENDING PI',
  'BRANDED PENDING PI',
  'IHL PENDING PI',
  "MORDY - PENDING PI",
  "PATTY - PENDING PI",
  "SHIRLEY PENDING PI",
]

Object.keys(factoryNames).sort().forEach(key => factories.push(key))

let uniqueFactories = [...new Set(factories)]

export {uniqueFactories, factoryNames}
