import React, {Component} from 'react'
import {Input, Dropdown} from 'semantic-ui-react'
import SectionTitle from './section-title.js'
import ContinueButton from './continue-button.js'
import CadDetailsTable from './cad-details-section/cad-details-table.js'

class CadDetailsSection extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      complete: false
    }
    this.editSection = this.editSection.bind(this)
    this.setComplete = this.setComplete.bind(this)
  }

  editSection() {
    this.props.editSection()
  }

  setComplete(complete) {
    this.setState({complete})
  }

  render() {
    let {markAsComplete, isActive, isEditPage, titleNum} = this.props
    let {complete} = this.state

    let cadIds = []
    if (window.newVpoPage != null && window.newVpoPage.styleNumbersTextarea != null) {
      cadIds = window.newVpoPage.styleNumbersTextarea.cadIds
    }

    return (
      <div>
        <SectionTitle num={titleNum} title='ENTER CAD DETAILS' complete={complete}/>
        {
          isActive
          ?
          <React.Fragment>
            <CadDetailsTable showBulkEdit={cadIds.length > 1} cadIds={cadIds} setComplete={this.setComplete} isEditPage={isEditPage}/>
            <ContinueButton markAsComplete={markAsComplete} complete={complete} />
          </React.Fragment>
          :
          complete || isEditPage
          ?
          <p style={{fontSize: '13px'}}>
            &nbsp;
            <a style={{float: 'right', cursor: 'pointer'}}
              onClick={this.editSection}>edit</a>
          </p>
          :
          null
        }
      </div>
    )
  }
}

export default CadDetailsSection
