import {isCustomer} from './is-role.js'
import {ADD_DAYS_TO_LOADING_DATE, ADD_DAYS_TO_EX_FACTORY_DATE, BAD_CONTAINER_NUMBERS, BAD_STYLE_NUMBERS} from '../../modules/constants'
import {notPurchasedFilter} from './not-purchased-filter.js'
import {isLetter} from '../prints/check-valid-style-code'
import {checkFutureAtsMonths} from './future_ats_months'
import {checkForPrint, checkForPattern} from './prints/filter'
import {getSalesOrdersForCad, getSalesOrdersFilteredHold, getSalesOrdersFilteredWorksheet, getSalesOrdersFilteredNoHoldOrWorksheet, getSalesOrdersFilteredByStyleNumber} from './sales-orders/get'
import {checkLoadingDate} from './check-loading-date'
import {getContainerNumbersFromPoDetail} from '../cad-card/get-container-numbers-from-po-detail'

const isCorrectOrderType = (cadStatus, order) => {
  let result

  switch(cadStatus) {
    case "PO":
      result = !order.hold && !order.worksheet //CANT BE A HOLD & CANT BE A WORKSHEET
      break

    case "Hold":
      result = !!order.hold
      break

    case "Worksheet":
      result = !!order.worksheet
      break

    default:
      console.log('invalid cadStatus')
  }

  return result
}

const isCorrectHoldQty = (order, min, max) => {
  let qty = Number(order.quantity)

  if (min !== 0) {
    if (qty < min) {
      return false
    }
  }

  if (max !== 0 && max !== 11000) {
    if (qty > max) {
      return false
    }
  }

  return true
}

const cadFilter = (cad, str, str_nospaces, myState, myProps) => {
  let result = true



  if (!myState.searchPONumber && !myState.searchContainerNumber && str_nospaces.length > 2) {
    let result_style_number = cad.style_number.toLowerCase().includes(str) && str.length > 2;

    //str_nospaces
    let result_style_number_nospaces;
    if (cad.style_number_nospaces == null) {
      result_style_number_nospaces = cad.style_number.replace(/\s/g, '').toLowerCase().includes(str_nospaces);
    } else {
      result_style_number_nospaces = cad.style_number_nospaces.toLowerCase().includes(str_nospaces);
    }

    result = result_style_number || result_style_number_nospaces
  }





  if (!myState.searchPONumber && !myState.searchContainerNumber) {
    let {minOnHand, maxOnHand, minHoldQty, maxHoldQty, minUnits, maxUnits, minFutureUnits, maxFutureUnits, maxPage, minPage} = myState


    //----------On Hand----------
    //allow on hand sliders to work with ATS  OR   Future ATS
    let usingOnHandSlider = false
    if (result && minOnHand !== 0) {
      usingOnHandSlider = true
      result = (cad.on_hand != null && cad.on_hand >= minOnHand)
    }
    if (result && maxOnHand !== 0 && maxOnHand !== 11000) {
      usingOnHandSlider = true
      result = (cad.on_hand != null && cad.on_hand <= maxOnHand)
    }

    //----------Hold Qty----------
    //
    if (result && (minHoldQty !== 0 || (maxHoldQty !== 0 && maxHoldQty !== 11000))) {
      let orders

      if (myState.salesHistoryAccount != '') {
        orders = getSalesOrdersFilteredHold(cad.style_number, cad.id)
      } else {
        orders = getSalesOrdersFilteredByStyleNumber(cad.style_number, cad.id)

        if (orders != null) {
          orders = orders.filter(x => isCorrectOrderType('Hold', x) )
        }
      }

      if (orders != null) {
        orders = orders.filter(x => isCorrectHoldQty(x, minHoldQty, maxHoldQty) )
      }

      if (orders != null && orders.length > 0) {

      } else {
        //no holds found
        result = false
      }
    }


    //----------ATS----------
    //if using ATS filter have a chance to rescue, but dont want to get rid of if marked ats true
    let usingATSFilter = false
    let wantToCheckATSFilter = (result && !usingOnHandSlider) || (!result && usingOnHandSlider)
    if (wantToCheckATSFilter) {
      let cad_available = cad.available
      let cad_on_hand = Number(cad.on_hand)
      if (typeof cad_available === "number" && cad_available > cad_on_hand) {
        cad_available = cad_on_hand
      }

      if (minUnits !== 0 ) {
        usingATSFilter = true

        result = (cad_available != null && cad_available >= minUnits)
      }
      if (result && maxUnits !== 0 && maxUnits !== 11000) {
        usingATSFilter = true

        result = (cad_available != null && cad_available <= maxUnits)
      }
    }


    //----------Future ATS----------
    //                         check we arent using other filters               rescue it maybe
    let wantToCheckFutureAts = (result && !usingATSFilter && !usingOnHandSlider) || (!result && (usingATSFilter || usingOnHandSlider))
    if (wantToCheckFutureAts) {
      if (isCustomer()) {
        if (minFutureUnits !== 0) {
          result = cad.future_ats >= minFutureUnits
        }
      } else {
        //dont want to rescue cad if slider is at 0
        let notRescuingZeroFutureAts = !(minFutureUnits === 0 && (usingATSFilter || usingOnHandSlider))

        if (minFutureUnits !== -3000 && notRescuingZeroFutureAts) {
          result = cad.future_ats >= minFutureUnits
        }
      }

      if (result && maxFutureUnits !== 0 && maxFutureUnits !== 11000) {
        result = cad.future_ats <= maxFutureUnits
      }
    }


    //----------Page----------
    //currently not OR logic with onhand, ats and future ats
    if (result && maxPage !== 800) {
      result = (cad.age != null && cad.age <= maxPage)
    }
    if (result && minPage !== 0) {
      result = (cad.age != null && cad.age >= minPage)
    }
  }


  if (result && myState.multiSearch.length > 2 && myState.multiSearchStyleNumbers != null) {
    let cadFound = false

    for (var i=0; i<myState.multiSearchStyleNumbers.length; i++) {
      let styleNumber = myState.multiSearchStyleNumbers[i]
      let styleNumber_nospaces = styleNumber.replace(/\s/g, '')
      let cad_style_number = cad.style_number
      let cad_style_number_no_spaces = cad_style_number.replace(/\s/g, '')

      let found = (
        myState.searchContainerNumber
        ?
        cad.container_number != null && cad.container_number.toLowerCase().split('@@@').some(x => x == styleNumber)
        :
        cad.style_number.toLowerCase().startsWith(styleNumber) || cad_style_number_no_spaces.toLowerCase().startsWith(styleNumber_nospaces)
      )

      if (found) {
        cadFound = true

        //save index so can sort
        if (!window.multiSearchIndex) {
          window.multiSearchIndex = {};
        }

        window.multiSearchIndex[cad.id] = i;

        if (!window.multiSearchIndexTotals) {
          window.multiSearchIndexTotals = {};
        }

        if (!window.multiSearchIndexTotals[i]) {
          window.multiSearchIndexTotals[i] = 1;
        } else {
          window.multiSearchIndexTotals[i] += 1;
        }



        break;
      }
    }

    result = cadFound;

    return result;
  }

  if (result && myState.searchString.length > 2) {
    if (myState.searchPONumber) {
      let orders = getSalesOrdersForCad(cad)
      let orders2 = window.salesAccountDataByStyleNumber[cad.style_number.flatUpcase()]

      if (orders != null) {
        orders = orders.filter(x => x.account_name == window.searchPONumberAccount)
      } else {
        orders = []
      }

      if (orders2 != null) {
        orders2 = orders2.filter(x => x.account_name == window.searchPONumberAccount)
      } else {
        orders2 = []
      }

      if (orders.length == 0 && orders2.length == 0) {
        return false
      } else {
        let ponumbers = []
        orders.concat(orders2).forEach(order => {
          let ponumber = order.ponumber.trimUpcase()
          ponumbers.push(ponumber)
        })

        return ponumbers.indexOf(window.searchPoNumber) > -1
      }
    } else if (myState.searchContainerNumber) {
      return (
        cad.container_number == null
        ?
        false
        :
        cad.container_number.indexOf(myState.searchString) > -1
      )

    } else {
      //result = cad.style_number.toLowerCase().startsWith(str);

      //allow any 3 consecutive characters to match
      //we already have min 3 characters? to do the function?
      //so just do a include substring thing?


      if (str.indexOf('linked:') > -1) {
        let family_id = Number(str.replace('linked:',''));
        result = cad.family_id == family_id;

      } else {
        let result_style_number = cad.style_number.toLowerCase().includes(str) && str.length > 2;



        //str_nospaces
        let result_style_number_nospaces;
        if (cad.style_number_nospaces == null) {
          result_style_number_nospaces = cad.style_number.replace(/\s/g, '').toLowerCase().includes(str_nospaces) && str_nospaces.length > 2;
        } else {
          result_style_number_nospaces = cad.style_number_nospaces.toLowerCase().includes(str_nospaces) && str_nospaces.length > 2;
        }


        //typo fixes, already entered into qb, just want to show up under search
        //let result_typo = cad.style_number.toLowerCase().replace('yg2f','ygzf').includes(str) && str.length > 2;



        result = result_style_number || result_style_number_nospaces// || result_typo
      }



    }
  }

  if (result && myProps.cadReducer.cadStatus == 'QC') {
    result = (cad.qc_issue_total != null && cad.qc_issue_total > 0);
  }

  if (result && myProps.cadReducer.cadStatus == 'Best Sellers') {
    if (myState.salesHistoryAccount == '' && myProps.cadReducer.defaultAccount == null) {
      result = (cad.best_seller_total != null && cad.best_seller_total > 0);
    } else {
      if (!!window.salesAccountBestSellers) {
        result = window.salesAccountBestSellers.indexOf(cad.id) > -1
      }
    }
  }

  if (result && myProps.cadReducer.cadStatus == 'Average Sellers') {
    if (myState.salesHistoryAccount == '' && myProps.cadReducer.defaultAccount == null) {
      result = (cad.average_seller_total != null && cad.average_seller_total > 0);
    } else {
      if (!!window.salesAccountAverageSellers) {
        result = window.salesAccountAverageSellers.indexOf(cad.id) > -1
      }
    }
  }

  if (result && myProps.cadReducer.cadStatus == 'Poor Sellers') {
    if (myState.salesHistoryAccount == '' && myProps.cadReducer.defaultAccount == null) {
      result = (cad.poor_seller_total != null && cad.poor_seller_total > 0);
    } else {
      if (!!window.salesAccountPoorSellers) {
       result = window.salesAccountPoorSellers.indexOf(cad.id) > -1
      }
    }
  }

  if (result && myProps.cadReducer.cadStatus == 'Do Not Sell') {
    let hasBadContainerNumber = false

    if (cad.container_number != null) {
      let containerNumbers = cad.container_number.split('@@@')
      hasBadContainerNumber = containerNumbers.some(x => BAD_CONTAINER_NUMBERS.includes(x))
    }

    let hasBadStyleNumber = BAD_STYLE_NUMBERS.includes(cad.style_number)

    result = hasBadContainerNumber || hasBadStyleNumber
  }

  if (result && myProps.cadReducer.cadStatus == 'Case Pack') {
    result = (cad.case_pack != null)
  }

  if (result && myProps.cadReducer.cadStatus == 'Container #') {
    let containerNumbers = getContainerNumbersFromPoDetail(cad)
    result = containerNumbers.length > 0
  }

  if (result && myProps.cadReducer.cadStatus == 'Container # w/ Updated Dates') {
    if (cad.container_number == null || window.container_number_data == null) {
      result = false
    } else {
      let nums = cad.container_number.split('@@@')

      result = nums.some(x => window.container_number_data[x] != null)
    }
  }

  if (result && myProps.cadReducer.cadStatus == 'Updated Dates from VPO in ERP') {
    if (window.open_vpos_dates == null) {
      result = false
    } else {
      result = window.open_vpos_dates[cad.style_number] != null && cad.po_detail != null
    }
  }

  if (result && myProps.cadReducer.cadStatus == 'Long Style Number') {
    result = (cad.long_style_number != null)
  }

  if (result && myProps.cadReducer.cadStatus == 'No Long Style Number') {
    result = (cad.long_style_number == null)
  }

  if (result && myProps.cadReducer.cadStatus == 'No Long Style Number w/ Future Stock') {
    result = (cad.long_style_number == null) && (cad.future_stock != null && cad.future_stock >= 1)
  }

  if (result && myProps.cadReducer.cadStatus == 'ETA / Loading Date') {
    result = (cad.loading_date != null);
  }

  if (result && myProps.cadReducer.cadStatus == 'No ETA / Loading Date') {
    result = (cad.loading_date == null);
  }

  if (result && myProps.cadReducer.cadStatus == 'FACTORY-RTS') {
    result = !!cad.fob_rts || (window.fob_rts_by_style != null && window.fob_rts_by_style[cad.style_number])
  }

  if (result && myProps.cadReducer.cadStatus == 'Bin Location') {
    result = !!cad.location
  }

  if (result && myProps.cadReducer.cadStatus == 'No Bin Location') {
    result = !cad.location
  }

  if (result && myProps.cadReducer.cadStatus == 'Pending PI') {
    result = !!cad.pending_pi //!! not really necessary
  }

  if (result && myProps.cadReducer.cadStatus == 'Deals') {
    //older than 300 days.... has ATS.... also if have is_a_deal = true, send up with serializer
    result = (cad.available != null && cad.available > 0) && ((cad.age != null && cad.age > 300) || cad.is_deal)
  }

  if (result && myProps.cadReducer.cadStatus == 'Gift with Purchase') {
    result = (cad.style_number[0] == 'Q' || cad.style_number[1] == 'Q')
  }

  if (result && myProps.cadReducer.cadStatus == 'Mexico Customer') {
    result = (
      (cad.style_number[0] == 'M' && isLetter(cad.style_number[1]) && isLetter(cad.style_number[2]))
      ||
      (cad.style_number[0] == 'M' && cad.style_number[1] == 'Q' && isLetter(cad.style_number[2]) && isLetter(cad.style_number[3]))
    )
  }

  if (result && myProps.cadReducer.cadStatus == 'Error Syncing') {
    result = (cad.quickbooks_style_number === null && cad.available != 0 && !cad.error_syncing_reviewed);
  }

  if (result && myProps.cadReducer.cadStatus == 'Not Purchased') {
    if (myState.salesHistoryAccount == '') {
      result = notPurchasedFilter(cad, myProps)
    } else {
      result = false
    }
  }

  if (result && myProps.cadReducer.cadStatus == 'Error Syncing and Reviewed') {
    result = (cad.quickbooks_style_number === null && cad.available != 0 && cad.error_syncing_reviewed);
  }

  if (result && myProps.cadReducer.cadStatus == 'Missing Fabrication') {
    result = ['', null].includes(cad.fabrication)
  }

  if (result && myProps.cadReducer.cadStatus == 'Missing MSRP') {
    result = ['', null].includes(cad.msrp)
  }


  if (result && myProps.cadReducer.cadStatus == 'Brand Approval Requested') {
    result = cad.is_branded
  }

  if (result && myProps.cadReducer.cadStatus == 'Brand Approved') {
    result = !!cad.is_licensee_approved
  }

  if (result && myProps.cadReducer.cadStatus == 'Brand Rejected') {
    result = cad.is_licensee_approved === false
  }


  if (result && myProps.cadReducer.cadStatus == 'PP Samples Requested') {
    result = cad.is_pp
  }

  if (result && myProps.cadReducer.cadStatus == 'PP Samples Approved') {
    result = !!cad.is_pp_approved
  }

  if (result && myProps.cadReducer.cadStatus == 'PP Samples Rejected') {
    result = cad.is_pp_approved === false
  }


  if (result && myProps.cadReducer.cadStatus == 'TOP Samples Requested') {
    result = cad.is_top
  }

  if (result && myProps.cadReducer.cadStatus == 'TOP Samples Approved') {
    result = !!cad.is_top_approved
  }

  if (result && myProps.cadReducer.cadStatus == 'TOP Samples Rejected') {
    result = cad.is_top_approved === false
  }


  if (result && myProps.cadReducer.cadStatus == 'Immediate') {

    let cad_available = cad.available
    let cad_on_hand = Number(cad.on_hand)
    if (typeof cad_available === "number" && cad_available > cad_on_hand) {
      cad_available = cad_on_hand
    }

    result = (cad_available != null && cad_available > 24) || checkLoadingDate(cad)
  }

  if (result && myProps.cadReducer.cadStatus == 'Oversold') {
    result = (cad.future_ats != null && cad.future_ats < -24)
  }

  if (result && myProps.cadReducer.cadStatus == 'On Hand' || myState.readyToShip) {
    result = (cad.on_hand != null && cad.on_hand >= 1)
  }

  if (result && ['PO','Hold','Worksheet'].includes(myProps.cadReducer.cadStatus)) {
    let salesOrders = getSalesOrdersFilteredByStyleNumber(cad.style_number, cad.id)

    if (salesOrders != null && myProps.cadReducer.defaultAccount != null) {
      salesOrders = salesOrders.filter(order => myProps.cadReducer.defaultAccount == order.account_name)
    }

    result = salesOrders != null && salesOrders.some(order => isCorrectOrderType(myProps.cadReducer.cadStatus, order))
  }

  if (result && (myProps.cadReducer.cadStatus == 'ATS or Future ATS' || myProps.cadReducer.cadStatus == 'Not Purchased')) {
    let cad_available = cad.available
    let cad_on_hand = Number(cad.on_hand)
    if (typeof cad_available === "number" && cad_available > cad_on_hand) {
      cad_available = cad_on_hand
    }

    let hasFutureAts = (cad.future_ats != null && cad.future_ats >= 1)
    result = (cad_available != null && cad_available >= 1) || hasFutureAts
  }

  if (result && myProps.cadReducer.margins.length > 0) {
    //null or '' ?
    result = cad.margin != null && cad.margin != '' && myProps.cadReducer.margins.indexOf(cad.margin) > -1;

    //rescue if using missing filter
    if (myProps.cadReducer.margins.indexOf('Missing') > -1 && !result && (cad.margin == null || cad.margin == '')) {
      result = true;
    }
  }

  if (result && myProps.cadReducer.defaultAccount != null && myState.salesHistoryAccount == '' && myProps.cadReducer.cadStatus == 'All') {
    result = !(cad.available === null || cad.available === '') || !(cad.future_ats === null || cad.future_ats === '');
  }

  if (result && myProps.cadReducer.cadStatus == 'No Image') {
    result = (cad.filename == null);
  }

  if (result && myProps.cadReducer.cadStatus == 'No Image w/ Future Stock') {
    result = (cad.filename == null) && (cad.future_stock != null && cad.future_stock >= 1)
  }

  if (result && myProps.cadReducer.cadStatus == 'Image') {
    result = (cad.filename != null);
  }

  if (result && myProps.cadReducer.cadStatus == 'No Sizes') {
    result = (cad.sizes == null || cad.sizes == '');
  }

  if (result && myProps.cadReducer.cadStatus == 'Sizes') {
    result = (cad.sizes != null && cad.sizes != '');
  }

  if (result && myProps.cadReducer.cadStatus == "Shipped") {
    if (myState.salesHistoryAccount != '') {
      result = !!window.salesAccountDataFiltered[cad.style_number.flatUpcase()];

      if (result && myState.inProgress) {
        let hasAnInProgressShippedOrder = false
        let shipped_orders_local = window.salesAccountDataFiltered[cad.style_number.flatUpcase()]

        for (var i=0; i<shipped_orders_local.length; i++) {
          if (!!shipped_orders_local[i].in_progress && ![0,'0',null].includes(shipped_orders_local[i].quantity)) {
            hasAnInProgressShippedOrder = true
            break
          }
        }

        result = hasAnInProgressShippedOrder
      }


    } else {
      if (!!window.salesAccountDataFilteredByStyleNumber) {
        result = !!window.salesAccountDataFilteredByStyleNumber[cad.style_number.flatUpcase()];

        if (result && myState.inProgress) {
          let hasAnInProgressOrder = false
          let invoices = window.salesAccountDataFilteredByStyleNumber[cad.style_number.flatUpcase()]

          for (var i=0; i<invoices.length; i++) {
            if (!!invoices[i].in_progress && ![0,'0',null].includes(invoices[i].quantity)) {
              hasAnInProgressOrder = true
              break
            }
          }

          result = hasAnInProgressOrder
        }



        if (result && myProps.cadReducer.defaultAccount != null) {
          let anyHaveAccount = false
          let invoices = window.salesAccountDataFilteredByStyleNumber[cad.style_number.flatUpcase()]

          for (var i=0; i<invoices.length; i++) {
            if (myProps.cadReducer.defaultAccount == invoices[i].account_name) {
              anyHaveAccount = true;
              break;
            }
          }

          result = anyHaveAccount;
        }



      }
    }
  }

  if (result && ["PO2", "Hold2", "Worksheet2"].indexOf(myProps.cadReducer.cadStatus) > -1) {
    if (myState.salesHistoryAccount != '') {
      if (myProps.cadReducer.cadStatus == "PO2") {
        result = !!getSalesOrdersFilteredNoHoldOrWorksheet(cad.style_number, cad.id)
      } else if (myProps.cadReducer.cadStatus == "Hold2") {
        result = !!getSalesOrdersFilteredHold(cad.style_number, cad.id)
      } else {
        result = !!getSalesOrdersFilteredWorksheet(cad.style_number, cad.id)
      }

    } else {
      if (!!window.salesOrderDataFilteredByStyleNumber && !!getSalesOrdersFilteredByStyleNumber(cad.style_number, cad.id)) {
        let hasASalesOrder = false
        let sales_orders_local = getSalesOrdersFilteredByStyleNumber(cad.style_number, cad.id)

        for (var i=0; i<sales_orders_local.length; i++) {
          if (
            isCorrectOrderType(myProps.cadReducer.cadStatus, sales_orders_local[i])
            &&
            (
              (myState.oversold ? !!sales_orders_local[i].oversold : false )
              ||
              (myState.hasVponumber ? !!sales_orders_local[i].vendor_ponumber : false )
              ||
              (myState.hasVpoLoadingDate ? !!sales_orders_local[i].loading_date : false )
            )
            &&
            (
              myProps.cadReducer.defaultAccount == null
              ||
              myProps.cadReducer.defaultAccount == sales_orders_local[i].account_name
            )
          ) {
            hasASalesOrder = true
            break
          }
        }

        result = hasASalesOrder
      } else {
        result = false
      }
    }

  }

  if (result && myProps.cadReducer.cadStatus == 'ATS') {
    let cad_available = cad.available
    let cad_on_hand = Number(cad.on_hand)
    if (typeof cad_available === "number" && cad_available > cad_on_hand) {
      cad_available = cad_on_hand
    }

    result = (cad_available != null && cad_available > 0)
  }

  if (result && myProps.cadReducer.cadStatus == 'Future ATS') {
    result = (cad.future_ats != null && cad.future_ats >= 1)
  }

  if (result && myProps.cadReducer.cadStatus == 'Future ATS w/ No ETA') {
    result = (cad.future_ats != null && cad.future_ats >= 1 && cad.loading_date == null)
  }

  if (result && myProps.cadReducer.cadStatus == 'Future ATS Confirmed') {
    result = (cad.future_ats != null && cad.future_ats >= 25 && !cad.pending_pi)
  }

  if (result && myProps.cadReducer.cadStatus == 'Future Stock') {
    result = (cad.future_stock != null && cad.future_stock >= 1)
  }

  if (result && myProps.cadReducer.factory.length > 0) {
    let cadFactoryIds = window.factory_ids_by_style[cad.style_number]
    result = cadFactoryIds != null && myProps.cadReducer.factory.some(item => cadFactoryIds.includes(item))
  }

  if (result && myProps.cadReducer.cadStatus == 'Factory') {
    result = !!window.factory_ids_by_style[cad.style_number]
  }

  if (result && myProps.cadReducer.warehouse.length > 0) {
    if (myProps.cadReducer.warehouse.length == 1 && myProps.cadReducer.warehouse[0] == 'MISSING') {
      result = cad.sites == null || cad.sites.length == 0
    } else {
      result = cad.sites.some(r => myProps.cadReducer.warehouse.includes(r))
    }
  }

  if (result && myProps.cadReducer.futureAtsMonths.length > 0) {

    let cadStatusFutureStock = (myProps.cadReducer.cadStatus == 'Future Stock')

    //get dates

    let newPoDetail = [];

    var poDetail = cad.po_detail
    let poDetailTaken = 0;
    if (cad.available < 0) {
      poDetailTaken = cad.available * -1;
    }

    if (poDetail != null) {
      let poDetailRows = poDetail.split(',');

      for(var k=0;k<poDetailRows.length;k++) {
        let parts = poDetailRows[k].split('—');
        let part1 = Number(parts[0]);
        let part5 = parts[4];



        let part2 = parts[1];
        let date = null;
        if (part2 != null && part2.trim() != '') {

          var etaParts = part2.trim().split('/');

          date = new Date(etaParts[2], etaParts[0]-1, etaParts[1]); // Now // why did i name say "Now"??

          date.setDate(date.getDate() + ADD_DAYS_TO_LOADING_DATE); // Set now + 60 days as the new date

          part2 = "ETA: " + (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
        }

        if (date == null && part5.trim() != '') {

          var delivDateParts = part5.trim().split('/');
          date = new Date(delivDateParts[2], delivDateParts[0]-1, delivDateParts[1]);

          date.setDate(date.getDate() + ADD_DAYS_TO_EX_FACTORY_DATE); // Set now + 30 days as the new date

          part5 = "" + (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
        }

        newPoDetail.push([part1, part2, part5, date]);

      }
    }

    if (!cadStatusFutureStock) {
      //sort po details by date!!!!
      newPoDetail.sort(function (a,b) {
        if (a[3] > b[3]) return 1;
        if (b[3] > a[3]) return -1;
      });

      //remove ATS taken after sorting!!!!
      //allow last order to go negative
      let numPo = newPoDetail.length
      for(var k=0;k<numPo;k++) {
        let isLastPo = (k + 1 == numPo)

        let part1 = newPoDetail[k][0];

        if (poDetailTaken != 0) {
          if (part1 > poDetailTaken || isLastPo) {
            part1 -= poDetailTaken;
            poDetailTaken = 0;
          } else {
            poDetailTaken -= part1;
            part1 = 0
          }
        }

        newPoDetail[k][0] = part1;
      }

      //Don't want any 0 or less Future ATS rows
      newPoDetail = newPoDetail.filter(function(value, index){
          let isZeroOrLess = value[0] <= 0;
          return !isZeroOrLess;
      });
    }

    let cadDates = newPoDetail.map(x => (x[3] == null ? null : x[3].toISOString().split('T')[0]) )

    cadDates = cadDates.filter(function (el) {
      return el != null;
    });

    //if any cad dates match need to return result
    for (let k=0; k < cadDates.length; k++) {
      let dateTextParts = cadDates[k].split('-')
      let cad_year = Number(dateTextParts[0])
      let cad_month = Number(dateTextParts[1])
      let date_is_good = false

      for ( var i=0;   i < myProps.cadReducer.futureAtsMonths.length ; i++) {
        var futureAtsMonth = myProps.cadReducer.futureAtsMonths[i];

        if (!date_is_good) {
          date_is_good = checkFutureAtsMonths(cad_month, cad_year, futureAtsMonth)
        }
      }

      if (date_is_good) {
        return true
      }
    }
    return false
  }

  if (result && myProps.cadReducer.print.length > 0) {
    result = checkForPrint(cad, myProps.cadReducer.print)
  }

  if (result && myProps.cadReducer.pattern.length > 0) {
    result = checkForPattern(cad, myProps.cadReducer.pattern)
  }

  return result
}

export default cadFilter
