import React, {Component} from 'react'
import {Dropdown, Input, Button} from 'semantic-ui-react'
import SectionTitle from './section-title.js'
import {buyerOptions, reorderOptions, repOptions} from '../vendor-orders/options.js'
import ContinueButton from './continue-button.js'
import Utils from "../../modules/utils"
import {dateLabel} from './cad-details-section/date-label.js'
import StyleNumbersTextarea from './select-cads/style-numbers-textarea.js'
import {contactOptions, defaultContactName} from '../new-contact-modal/contact-options'

const hasDuplicates = (array) => {
  return (new Set(array)).size !== array.length
}

const isComplete = (buyer, ponumber, salesRepId, accountId, isEditPage) => {
  return buyer != null && ponumber != '' && ponumber != null && (salesRepId != null || isEditPage) && (accountId != null || isEditPage)
}

class SelectBuyer extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      buyer: null,
      reorder: null,
      ponumber: null,
      salesRepId: null,
      accountId: null,
      accountOptions: null,
      styleNumbersComplete: false,
      styleNumbers: [],
      loading: false
    }

    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.generateUniquePonumber = this.generateUniquePonumber.bind(this)
    this.setComplete = this.setComplete.bind(this)
    this.setStyleNumbers = this.setStyleNumbers.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.markAsComplete = this.markAsComplete.bind(this)
  }

  componentDidMount() {
    let {isEditPage} = this.props

    if (!isEditPage) {
      this.generateUniquePonumber()
    }

    window.addEventListener('load-edit-vpo-data', () => {
      this.setState(window.editVpoPage.selectBuyer)
    })
  }

  componentDidUpdate() {
    window.newVpoPage.selectBuyer = JSON.parse(JSON.stringify(this.state))
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    newState[name] = value
    this.setState(newState)
  }

  generateUniquePonumber() {
    let now = new Date()
    let secondsSinceEpoch = Math.round(now.getTime() / 1000).toString()
    let lastSix = secondsSinceEpoch.substring(3, 9)
    this.setState({ponumber: lastSix})
  }

  setComplete(complete) {
    this.setState({styleNumbersComplete: complete})
  }

  setStyleNumbers(styleNumbers) {
    this.setState({styleNumbers})
  }

  setLoading(loading) {
    this.setState({loading})
  }

  markAsComplete() {
    let {markAsComplete} = this.props
    let {styleNumbers} = this.state

    if (hasDuplicates(styleNumbers)) {
      alert('Remove duplicate style numbers!')
    } else {
      markAsComplete()
    }
  }

  render() {
    let {isActive, editSection, isEditPage, titleNum, stopLoading, customer} = this.props
    let {buyer, reorder, ponumber, salesRepId, accountId, styleNumbers, styleNumbersComplete, loading} = this.state
    let complete = isComplete(buyer, ponumber, salesRepId, accountId, isEditPage)

    let _repOptions = customer == null ? [] : repOptions(customer, true)
    let _contactOptions = customer == null ? [] : contactOptions(customer, true)
    let repOption = salesRepId == null || customer == null ? '' : _repOptions.find(x => x.value == salesRepId)
    let repOptionText = repOption == null ? '' : repOption.text
    let accountOption = accountId == null || customer == null ? '' : _contactOptions.find(x => x.value == accountId)
    let accountOptionText = accountOption == null ? '' : accountOption.text

    return (
      <div>
        <SectionTitle num={titleNum} title='ENTER BUYER, REORDER, PO #, REP, CONTACT AND CADS' complete={complete}/>

        <div style={isActive ? {} : {display: 'none'}}>
          <div style={{width: '250px', display: 'inline-block'}}>
            {dateLabel('Buyer', {}, buyer == null || buyer == '')}
            <Dropdown placeholder='Buyer' fluid selection search options={buyerOptions} onChange={this.fieldOnChange} name='buyer' value={buyer}/>
          </div>

          <div style={{width: '250px', display: 'inline-block', marginLeft: '15px', display: 'none'}}>
            {dateLabel('Reorder', {}, reorder == null || reorder == '')}
            <Dropdown placeholder='Reorder' fluid selection search options={reorderOptions} onChange={this.fieldOnChange} name='reorder' value={reorder}/>
          </div>

          <div style={{width: '250px', display: 'inline-block', marginLeft: '15px'}}>
            {dateLabel('PO #', {}, ponumber == null || ponumber == '')}
            <Input placeholder='PO #' fluid onChange={this.fieldOnChange} name='ponumber' value={ponumber}/>
          </div>
          {
            ponumber == '' || ponumber == null
            ?
            <Button style={{marginLeft: '15px'}} size='mini' primary onClick={this.generateUniquePonumber}>Generate PO #</Button>
            :
            null
          }

          <div style={{width: '57%', marginTop: '10px'}}>
            {dateLabel('Rep', {}, salesRepId == null && !isEditPage)}
            <Dropdown placeholder='Rep' fluid selection search options={_repOptions} onChange={this.fieldOnChange} name='salesRepId'
              value={salesRepId}
            />
          </div>

          <div style={{width: '57%', marginTop: '10px'}}>
            {dateLabel('Contact', {}, accountId == null && !isEditPage)}
            <Dropdown placeholder='Contact' fluid selection search options={_contactOptions} onChange={this.fieldOnChange} name='accountId'
              value={[null,0].includes(accountId) && isEditPage ? defaultContactName : accountId}
            />
          </div>

          <div style={{marginTop: '10px'}}>
            {dateLabel('Style Numbers', {}, !loading && styleNumbers.length == 0)}
            <StyleNumbersTextarea setComplete={this.setComplete} setStyleNumbers={this.setStyleNumbers}
              stopLoading={stopLoading} setLoading={this.setLoading} isEditPage={isEditPage}
            />
            <p style={{fontSize: '13px'}}>
              {
                isEditPage
                ?
                'You CANNOT edit Style Number (Cancel VPO and make New)'
                :
                <span>Each style number must be on a separate line! <span style={styleNumbers.length > 10 ? {color: 'red', fontWeight: 'bold', background: 'yellow'} : {}}>(max of 10)</span></span>
              }
            </p>
          </div>

          {
            styleNumbers.length > 10
            ?
            <h1><center>LIMIT OF 10 STYLES AT A TIME!!!</center></h1>
            :
            <ContinueButton markAsComplete={this.markAsComplete} complete={complete && styleNumbersComplete}
              generateCads={complete && !styleNumbersComplete && styleNumbers.length > 0 && styleNumbers.length <= 10 && !loading}
            />
          }
        </div>

        <p style={isActive ? {display: 'none'} : {fontSize: '13px'}}>
          {buyer}  <span style={{paddingLeft: '15px'}}>PO #: {ponumber}</span>

          <span style={{paddingLeft: '15px'}}>{repOptionText}</span>

          <span style={{paddingLeft: '15px'}}>
            {accountOptionText}
            {accountId == null && isEditPage ? defaultContactName : null}
          </span>

          <a className="noselect" style={complete ? {float: 'right', cursor: 'pointer'} : {display: 'none'}}
            onClick={editSection}>edit</a>
        </p>

      </div>
    )
  }
}

export default SelectBuyer
