const colorOptions = [
  "1-19 = BLACK",
  "20-59 = GRAY",
  "60-99 = HEATHERED GRAY",
  "100 = WHITE",
  "101-109 = IVORY",
  "110 = TIE DYE",
  "111-163 = IVORY",
  "164 = OFF-WHITE",
  "165 = GOLD",
  "201-249 = BROWN",
  "250-289 = BEIGE",
  "300-339 = GREEN",
  "340-469 = BLUE",
  "500-539 = PURPLE",
  "600-639 = RED",
  "650-699 = PINK",
  "700-749 = YELLOW",
  "800-839 = ORANGE",
  "840-859 = CORAL",
  "860-920 = WHITE",
  "921-999 = PINK",
].map((x) => {return { key: x, text: x, value: (x.split('=')[0].includes('-') ? x.split('-')[0] : x.split(' ')[0])}})

const categoryOptions = [
  "A = ACTIVEWEAR",
  "H = HOSIERY",
  "Z = BASICS / INTIMATES",
  "S = SLEEP / LOUNGE",
  "K = SOCKS",
  "C = ACCESSORIES",
  "R = SHAPEWEAR",
  "P = APPAREL",
  "M = PPE",
  "O = HAMMOCKS",
  "F = FOOTWEAR",
  "B = BAGS",
  "L = ATHLEISURE",
  "T = OUTERWEAR",
  "G = LIGHT OUTERWEAR",
  "W = SWIMWEAR",
  "D = BEDS",
  "Y = TOYS",
  "J = CARRIERS",
  "U = HUTS",
].sort().map((x) => {return { key: x, text: x, value: x[0]}})

const packsOptions = [
  "A = SINGLE",
  "B = 2 PACK",
  "C = 3 PACK",
  "D = 4 PACK",
  "E = 5 PACK",
  "F = 6 PACK",
  "G = 7 PACK",
  "H = 8 PACK",
  "J = 9 PACK",
  "K = 10 PACK",
  "L = 11 PACK",
  "M = 12 PACK",
  "N = 13 PACK",
  "O = 14 PACK",
  "S = SET",
  "P = PACKAGED",
  "Q = 20 PACK",
].sort().map((x) => {return { key: x, text: x, value: x[0]}})

const divisionOptions = [
  "W = LADIES",
  "W = LADIES PLUS SIZE",
  "M = MEN",
  "G = GIRLS",
  "B = BOYS",
  "D = INFANT GIRL",
  "C = INFANT BOY",
  "N = TODDLER GIRL",
  "O = TODDLER BOY",
  "R = BIG BOY",
  "U = ADULTS UNISEX",
  "H = HOME",
  "L = LITTLE BOYS",
  "K = KIDS UNISEX",
  "J = JUNIOR GIRLS",
  "T = NEWBORN GIRL",
  "V = NEWBORN BOY",
  "P = PETS",
  "Y = JUNIORS MISSY",
].sort().map((x) => {return { key: x, text: x, value: x}})

const brandOptions = [
  {key: "1 = LUX DREAMS"},
  {key: "2 = FRANCO SARTO", category_char: ['K']},
  {key: "2 = PEANUTS", category_char_not: ['K']},
  {key: "3 = LAUREN MOSHI"},
  {key: "4 = THIS GIRL CAN"},
  {key: "5 = PARIS HILTON", division_char_not: ['D','G','J','N']},
  {key: "5 = PARIS HILTON GIRLS", division_char: ['D','G','J','N']},
  {key: "6 = JASON WU"},
  {key: "7 = GIRL SQUAD"},
  {key: "8 = LIFE IS GOOD"},
  {key: "9 = BEAR PAW"},
  {key: "A = AEROPOSTALE"},
  {key: "B = NUBODYWEAR", division_char: ['W']},
  {key: "B = BANANA BOAT", division_char_not: ['W']},
  {key: "C = KIDZ BOP", division_char: ['G','N','B','O']},
  {key: "C = FIT COLLECTIVE", division_char_not: ['G','N','B','O']},
  {key: "D = DAISY FUENTES"},
  {key: "E = EXERSAUCER", division_char: ['T','D','V','C']},
  {key: "E = TOTALLY TWEEN", division_char_not: ['T','D','V','C']},
  {key: "F = FRENCH AFFAIR"},
  {key: "G = GIRLS"},
  {key: "H = LIVERPOOL", division_char: ['C','D','N','O']},
  {key: "H = BIG DREAMS", division_char_not: ['C','D','N','O']},
  {key: "J = WILT"},
  {key: "K = BE HAPPY"},
  {key: "L = DANSKIN", division_char: ['G', 'W']},
  {key: "L = TODDLER", division_char_not: ['G', 'W']},
  {key: "M = JOE MAX JR", division_char_not: ['G', 'W']},
  {key: "M = SEALY", division_char: ['G', 'W']},
  {key: "N = RAE DUNN"},
  {key: "P = PRIVATE LABEL"},
  {key: "Q = K-SWISS"},
  {key: "R = TOTALLY ADORBS"},
  {key: "S = CHOOSE HAPPY", division_char: ['V','C','O','L','R','B','T','D','N','J','G']},
  {key: "S = SECRET STYLES", division_char_not: ['V','C','O','L','R','B','T','D','N','J','G']},
  {key: "T = TAHARI"},
  {key: "U = BCBG MAXAZRIA"},
  {key: "V = BCBG GENERATION"},
  {key: "W = WAIST WATCHER", category_char_not: ['K','P','T']},
  {key: "W = RBX", category_char: ['K','P','T']},
  {key: "X = CHARLEE RAE"},
  {key: "Y = ICY HOT"},
  {key: "Z = TIMREE"},
].sort().map((x) => {
  x['text'] = x['key']
  x['value'] = x['key']
  return x
})

const compare = (a, b) => {
    if (a['key'] < b['key'])
        return -1;
    if (a['key'] > b['key'])
        return 1;
    return 0;
}

const subcategoryOptions = [
  {key: "A = BATH ACCESSORIES", category_char: ['C']},
  {key: "A = APPAREL SET", category_char: ['P'], packs_char: ['S']},
  {key: "A = ACTIVE SET", category_char: ['A'], packs_char: ['S']},
  {key: "A = LS/LL PJ SET", category_char: ['S'], packs_char: ['S']},

  {key: "B = BABY BAG", category_char: ['B']},
  {key: "B = PANTY [BRIEF]", division_char: ['G', 'W', 'D','N','J','T'], packs_char_not: ['S'], category_char: ['Z']},
  {key: "B = BRA & PANTY SET [BRIEF]", division_char: ['G', 'W', 'D','N','J','T'], packs_char: ['S'], category_char: ['Z']},
  {key: "B = UNDERWEAR [BRIEF]", division_char: ['M', 'B', 'C','O','R','L','V'], category_char: ['Z']},

  {key: "C = CARRY ALL", category_char: ['B']},
  {key: "C = SS/LL PJ SET", category_char: ['S'], packs_char: ['S']},
  {key: "C = PANTY [CHEEKY / TANGA]", division_char: ['G', 'W'], packs_char_not: ['S'], category_char: ['Z']},
  {key: "C = BRA & PANTY SET [CHEEKY / TANGA]", division_char: ['G', 'W'], packs_char: ['S'], category_char: ['Z']},
  {key: "C = UNDERWEAR [BOXER BRIEF]", division_char: ['M', 'B', 'C','O','R','L','V'], category_char_not: ['B', 'S']},

  {key: "D = FACE MASKS", category_char: ['M']},
  {key: "D = DRAWSTRING BACKPACK", category_char: ['B']},
  {key: "D = PANTY [BIKINI]", packs_char_not: ['S'], category_char: ['Z']},
  {key: "D = BRA & PANTY SET [BRA W/ BIKINI]", packs_char: ['S'], category_char: ['Z']},

  {key: "E = CLEANING SUPPLIES", category_char: ['M']},
  {key: "E = BELT BAG", category_char: ['B']},
  {key: "E = PANTY [THONG]", packs_char_not: ['S'], category_char: ['Z']},
  {key: "E = BRA & PANTY SET [BRA W/ THONG]", packs_char: ['S'], category_char: ['Z']},

  {key: "F = AIR SPRAY", category_char: ['M']},
  {key: "F = DUFFLE", category_char: ['B']},
  {key: "F = PANTY [GSTRING]", packs_char_not: ['S'], category_char: ['Z']},
  {key: "F = BRA & PANTY SET [BRA W/ GSTRING]", packs_char: ['S'], category_char: ['Z']},

  {key: "G = HAND SANITIZER", category_char: ['M']},
  {key: "G = GIFTABLE SET", category_char: ['B']},
  {key: "G = PANTY [HIPSTER]", packs_char_not: ['S'], category_char: ['Z']},
  {key: "G = BRA & PANTY SET [BRA W/ HIPSTER]", packs_char: ['S'], category_char: ['Z']},

  {key: "H = BIBS", category_char: ['C']},
  {key: "H = SWEATERS", category_char: ['A','P']},
  {key: "H = HOBO", category_char: ['B']},
  {key: "H = PANTY [HIGHWAISTED]", packs_char_not: ['S'], category_char: ['Z']},
  {key: "H = BRA & PANTY SET [BRA W/ HIGHWAISTED]", packs_char: ['S'], category_char: ['Z']},

  {key: "J = JACKETS", category_char: ['A','P','L','T','G']},
  {key: "J = DIAPER BACK-PACK", category_char: ['B']},
  {key: "J = PANTY [BOYSHORT / BOYLEG]", packs_char_not: ['S'], category_char: ['Z']},
  {key: "J = BRA & PANTY SET [BRA W/ BOYSHORT / BOYLEG]", packs_char: ['S'], category_char: ['Z']},

  {key: "K = DRESSES", category_char: ['P']},
  {key: "K = PHONE CASE WALLET/PASSPORT", category_char: ['B']},
  {key: "K = WAIST CINCHER", category_char: ['R']},
  {key: "K = BABYDOLL", category_char: ['Z']},

  {key: "L = BLANKET PACK", category_char: ['C']},
  {key: "L = SHOPPER", category_char: ['B']},
  {key: "L = VEST", category_char: ['A','P']},
  {key: "L = BRA [REMOVABLE CUPS]", category_char: ['Z']},

  {key: "M = BRA [MOLDED CUPS]", category_char: ['Z']},
  {key: "M = HAIR ACCESSORIES", category_char: ['C'], division_char: ['D','N','T']},

  {key: "N = BOX SET", category_char: ['C']},
  {key: "N = ROBE-WRAP", category_char: ['S']},
  {key: "N = KIMONOS", category_char: ['A','P']},
  {key: "N = BRALETTE/SOFT BRA", category_char: ['Z']},

  {key: "O = OVERNIGHTER", category_char: ['B']},
  {key: "O = BRA & PANTY SET", category_char: ['Z'], packs_char: ['S']},

  {key: "P = BACKPACK", category_char: ['B']},
  {key: "P = BRA [PUSH-UP]", category_char: ['Z']},

  {key: "Q = TRAINING PANT", division_char: ['C','D','N','O']},
  {key: "Q = CHAIR", category_char: ['O'], division_char: ['H']},
  {key: "Q = BRA [SPORTS BRA]", division_char: ['G', 'W'], category_char: ['A','P','Z']},

  {key: "R = CROSS-BODY", category_char: ['B']},
  {key: "R = REGULAR", category_char: ['O'], division_char: ['H']},
  {key: "R = BRA [WIRE-FREE]", category_char: ['Z'], division_char: ['G', 'W']},

  {key: "S = TRAVEL SET", category_char: ['S'], packs_char: ['S']},
  {key: "S = COSMETIC/SLG", category_char: ['B']},
  {key: "S = SCRUBS", category_char: ['M']},
  {key: "S = BODYSUIT", category_char: ['Z']},

  {key: "T = SWIMSUITS", category_char: ['W']},
  {key: "T = CHEMISE/SLEEPSHIRT", category_char: ['S']},
  {key: "T = TOTE", category_char: ['B']},
  {key: "T = SLIPS/SKIRTS", category_char_not: ['B', 'S', 'W']},

  {key: "U = SWIM SET", category_char: ['W'], packs_char: ['S']},
  {key: "U = BURP CLOTHS", category_char: ['C']},
  {key: "U = SHOULDER", category_char: ['B']},

  {key: "V = COVERUPS", category_char: ['W']},
  {key: "V = LOVEYS", category_char: ['C']},
  {key: "V = POOL SLIDES", category_char: ['F']},
  {key: "V = PANT", category_char_not: ['C', 'F', 'W']},

  {key: "W = MIXED ACCESSORIES", category_char: ['C']},
  {key: "W = WEEKENDER", category_char: ['B']},
  {key: "W = SLIPPERS", category_char: ['F']},
  {key: "W = SHORTS", category_char_not: ['B', 'C', 'F']},

  {key: "X = SECURITY BLANKETS", category_char: ['C']},
  {key: "X = SNEAKERS", category_char: ['F']},
  {key: "X = TOP", category_char_not: ['C', 'F']},

  {key: "Y = SHORTY SET", category_char: ['S'], packs_char: ['S']},
  {key: "Y = SWEATSHIRT/HOODIE", category_char: ['A','P']},
  {key: "Y = SWADDLE PACK", category_char: ['C']},
  {key: "Y = FLIP FLOPS", category_char: ['F']},

  {key: "Z = SWIM ACCESSORIES", category_char: ['C']},
  {key: "Z = JUMPSUIT/ROMPER", category_char: ['A', 'P']},
  {key: "Z = ONESIE", category_char: ['S']},
  {key: "Z = SOCKS", category_char: ['K']},

  //pets

  {key: "D = DONUT", division_char: ['P'], category_char: ['D']},
  {key: "C = COUCH", division_char: ['P'], category_char: ['D']},
  {key: "S = SOFA", division_char: ['P'], category_char: ['D']},
  {key: "P = PILLOW/CLASSIC", division_char: ['P'], category_char: ['D']},
  {key: "E = COMFORTER SET", division_char: ['P', 'H'], category_char: ['D'], packs_char: ['S']}, //home
  {key: "T = SCRATCHER", division_char: ['P'], category_char: ['D']},
  {key: "V = CAVE", division_char: ['P'], category_char: ['D']},
  // {key: "H = SWEATER", division_char: ['P'], category_char: ['P']},
  // {key: "J = JACKET", division_char: ['P'], category_char: ['P']},
  {key: "F = PLUSH", division_char: ['P'], category_char: ['Y']},
  {key: "R = RUBBER", division_char: ['P'], category_char: ['Y']},
  {key: "B = BOWL", division_char: ['P'], category_char: ['C']},
  {key: "G = GROOMING", division_char: ['P'], category_char: ['C']},
  {key: "L = LEASH", division_char: ['P'], category_char: ['C']},
  {key: "M = COLLAR", division_char: ['P'], category_char: ['C']},
  {key: "A = THROWS", division_char: ['P'], category_char: ['C']},
  {key: "Y = TOY", division_char: ['P'], category_char: ['C']},
].sort(compare).map((x) => {
  x['text'] = x['key']
  x['value'] = x['key']
  return x
})



export {colorOptions, categoryOptions, packsOptions, divisionOptions, brandOptions, subcategoryOptions}
