import React, {Component} from 'react'
import {Grid, Dropdown} from 'semantic-ui-react'

class VendorOrdersDropdown extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {placeholder, name, options, onChange, value, loading, style, width} = this.props

    return (
      <Grid.Column width={width}>
        <Dropdown
          placeholder={placeholder}
          name={name}
          fluid
          multiple
          search
          selection
          options={options}
          onChange={onChange}
          value={value}
          loading={loading}
          disabled={loading}
          style={style}
        />
      </Grid.Column>
    )
  }
}

export default VendorOrdersDropdown
