const labels = [
  'REF #', 'PO #', 'STYLE #', 'IMAGE', 'FACTORY ID', 'BUYER', 'QTY', 'PRICE', 'STATUS', 'STAGE',
  'CUSTOMER PO #', 'SIZE',
  'CREATED DATE', 'DELIV DATE', 'EX-FACTORY DATE', 'ORIGINAL EX-FACTORY DATE',
  'LOADING DATE', 'CONTAINER #', 'MEMO', 'CASE PACK', '# OF CARTONS', 'POLIBAG', 'FABRIC WEIGHT', 'FABRIC CONTENT', 'COUNTRY',
  'FACTORY-RTS DATE', 'ASN X-FACTORY DATE',
  'DISCOUNT', 'TOTAL $', 'TRANSACTION ID', 'AMOUNT PAID', 'TIME PAID', 'TIME CANCELLED', 'SITE / WAREHOUSE',
  'BRAND', 'DIVISION', 'CATEGORY', 'SUB-CATEGORY', 'COLOR', 'FABRICATION', 'PACKS', 'REP'
]

export {labels}
