import React, {Component} from 'react'
import {Message, Image, Checkbox} from 'semantic-ui-react'
import Utils from '../../modules/utils'

class DownloadVpos extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.downloadVendorQbImportExcel = this.downloadVendorQbImportExcel.bind(this)
  }

  downloadVendorQbImportExcel() {
    Utils.get('vendor_order_qb_import', (err, result) => {
      if (err) {
        alert('There was an error when trying to download excel')
      } else {
        window.location = result.data.url
      }
    })
  }


  render() {
    const today = new Date()

    return (
      <Message style={{minWidth: '975px', display: 'none'}}>
        <Message.Header>Download <u>VENDOR ORDER</u> QB Import Excel &nbsp; <small style={{opacity: 0.5, fontSize: '12px', float: 'right'}}>(Upload to <Image src='https://erp-rails-usa-production.s3.amazonaws.com/images/qb_logo.png' avatar /> once a week)</small></Message.Header>
        <Message.List>

          <Message.Item><a onClick={this.downloadVendorQbImportExcel} style={{cursor: 'pointer'}}>DOWNLOAD ALL</a></Message.Item>

          <center style={{fontSize: '12px'}}>This is &nbsp; <b><i>Processed</i></b> &nbsp; status within 30 days of EX-FACTORY DATE</center>
        </Message.List>
      </Message>
    )
  }
}

export default DownloadVpos
