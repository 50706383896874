import React from 'react'
import {Popup, Button} from 'semantic-ui-react'

const bestSellersBulkButtons = (markAsBestSeller, markAsAverageSeller, markAsPoorSeller) => {
  return (
    <div style={{display: 'inline-block'}}>
      <Popup content='Mark as best seller' position='bottom left' trigger={
        <Button id="mark-as-best-seller" basic icon='thumbs up outline' onClick={markAsBestSeller} style={{padding: '14px', marginRight: '10px'}}/>
      } />
      <Popup content='Mark as average seller' position='bottom left' trigger={
        <Button id="mark-as-average-seller" basic icon='smile outline' onClick={markAsAverageSeller} style={{padding: '14px', marginRight: '10px'}}/>
      } />
      <Popup content='Mark as poor seller' position='bottom left' trigger={
        <Button id="mark-as-worst-seller" basic icon='thumbs down outline' onClick={markAsPoorSeller} style={{padding: '14px', marginRight: '10px'}}/>
      } />
    </div>
  )
}

export {bestSellersBulkButtons}
