import {sendUpdateNumSelectedEvent} from './send-update-num-selected-event.js'
import {updateSelected} from './update-selected.js'
import {processText} from '../new-vpo-page/select-cads/process-text.js'
import {parseDate} from './vpo-sort'

const filterVendorOrders = (myState) => {
  let {vendorOrders, archivedVpos} = window
  let {status, payments, factory, buyer, brand, division, category, searchString, fromDate, toDate, showBulk, showEditedOn, rep} = myState
  let filteredVendorOrders = []

  let searchStrings = (showBulk ? processText(searchString) : [])
  let usingNonStatusFilters = (payments.length || factory.length || buyer.length || brand.length || division.length || category.length || searchString != '' || fromDate != null || toDate != null || rep.length)

  if (status.length || usingNonStatusFilters || showEditedOn) {
    let isSearching = (searchStrings.length > 0 && searchStrings[0] != '')

    if (status.includes('Shipped') || status.includes('Cancelled') || isSearching) {
      filteredVendorOrders = filteredVendorOrders.concat(archivedVpos)
    }

    if (status.includes('Pending') || status.includes('Processed') || status.includes('QB-Entered') || status.includes('Ready-To-Ship') || isSearching || usingNonStatusFilters || showEditedOn) {
      filteredVendorOrders = filteredVendorOrders.concat(vendorOrders)
    }

    if (status.length) {
      filteredVendorOrders = filteredVendorOrders.filter(x => status.indexOf(x.status) > -1)
    }

    if (payments.includes('PAID')) {
      filteredVendorOrders = filteredVendorOrders.filter(x => x.time_paid != null)
    }

    if (payments.includes('UNPAID')) {
      filteredVendorOrders = filteredVendorOrders.filter(x => x.time_paid == null)
    }

    if (factory.length) {
      filteredVendorOrders = filteredVendorOrders.filter(x => factory.indexOf(x.factory_id) > -1)
    }

    if (buyer.length) {
      filteredVendorOrders = filteredVendorOrders.filter(x => buyer.indexOf(x.buyer_name == null ? 'Pending' : x.buyer_name) > -1)
    }

    if (brand.length) {
      filteredVendorOrders = filteredVendorOrders.filter(x => brand.indexOf(x.cad.brand) > -1)
    }

    if (division.length) {
      filteredVendorOrders = filteredVendorOrders.filter(x => division.indexOf(x.cad.department) > -1)
    }

    if (category.length) {
      filteredVendorOrders = filteredVendorOrders.filter(x => category.indexOf(x.cad.parent_category) > -1)
    }

    if (isSearching) {
      filteredVendorOrders = filteredVendorOrders.filter(x => searchStrings.includes(x.refnumber) || searchStrings.includes(x.vponumber) || searchStrings.includes(x.style_number) || searchStrings.includes(x.transaction_id))
    }

    if (fromDate != null) {
      filteredVendorOrders = filteredVendorOrders.filter(x => dateFormatSlashToDash(x.ex_factory) >= fromDate)
    }

    if (toDate != null) {
      filteredVendorOrders = filteredVendorOrders.filter(x => dateFormatSlashToDash(x.ex_factory) <= toDate)
    }

    if (showEditedOn) {
      // let d = new Date()
      // d.setDate(d.getDate() - 10)
      // filteredVendorOrders = filteredVendorOrders.filter(x => x.edited && parseDate(x.edited_on) > d)
      filteredVendorOrders = filteredVendorOrders.filter(x => x.edited)
    }

    if (rep.length) {
      filteredVendorOrders = filteredVendorOrders.filter(x => rep.includes(x.sales_rep_id))
    }

    window.filteredVendorOrders = filteredVendorOrders
  } else {
    window.filteredVendorOrders = undefined
  }
  afterFilterChange()

  let statuses = []
  if (showBulk) {
    let duplicates = {}

    searchStrings.forEach((element, index) => {
      if (element == '') {
        statuses.push(null)
      } else {
        if (duplicates[element]) {
          statuses.push('duplicate')
        } else {
          if (filteredVendorOrders.some(x => element == x.refnumber || element == x.vponumber || element == x.style_number || element == x.transaction_id)) {
            statuses.push('found')
          } else {
            statuses.push('not found')
          }

          duplicates[element] = true
        }
      }
    })
  }

  return statuses
}

const afterFilterChange = () => {
  window.vpoIds = {}
  sendUpdateNumSelectedEvent()
  updateSelected()
}

const dateFormatSlashToDash = (dateString) => {
  let parts = dateString.split('/')
  return  parts[2] + '-' + parts[0].padStart(2, '0') + '-' + parts[1].padStart(2, '0')
}

export default filterVendorOrders
