import React, {Component} from 'react'
import {Transition, Table, Button, Icon, Popup} from 'semantic-ui-react'
import $ from 'jquery'
import {sendUpdateNumSelectedEvent} from './send-update-num-selected-event'
import {displayDelivDate} from './display-deliv-date'
import {labels} from './labels'
import {roundToPenny} from './round-to-penny'
import TableHeader from './table-header'
import {removeLeadingZeros} from './remove-leading-zeros'
import Utils from "../../modules/utils"
import {getStatusMarkup} from '../purchase-orders/sales-view/get-status-markup'
import {fabricationTableCell} from './fabrication-table-cell'
import {imageTableCell} from './image-table-cell'
import {getTotalDollar} from './vpo-sort'
import {factoryNames} from './options'
import {pageLabel} from './table/page-label'
import {moreResults} from './table/more-results'
import {getIsLocked, getIsUnlocked, isLockedTooltip, stickyNoteStyle, isUnlockedTooltip} from '../new-vpo-page/get-is-locked'
import {reopen} from './reopen'
import VpoStages from './stages'

class VendorOrdersTable extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.vpoTableForceUpdate = this.vpoTableForceUpdate.bind(this)
  }

  componentDidMount() {
    window.addEventListener('vpoTableForceUpdate', this.vpoTableForceUpdate)
  }

  vpoTableForceUpdate() {
    this.forceUpdate()
  }

  downloadPdf(refnumber) {
    let data = {successId: refnumber}
    Utils.post('vpo_pdf', data, (err, result) => {
      if (err) {
        alert('There was an error when trying to download pdf')
      } else {
        window.open(result.data.url, '_blank', "height=500,width=800")
      }
    })
  }

  vpoCheckboxOnChange(id, cadId, index) {
    let idText = id+'-'+cadId
    if (id == null || cadId == null) {
      idText = index
      id = 'index'
      cadId = index
    }
    let checked = $('#vpo-checkbox-'+idText).is(':checked')
    let {vpoIds} = window

    if (checked) {
      if (vpoIds[id] == null) {
        vpoIds[id] = {}
      }
      vpoIds[id][cadId] = true
    } else {
      delete vpoIds[id][cadId]
      if (Object.keys(vpoIds[id]).length == 0) {
        delete vpoIds[id]
      }
    }

    window.vpoIds = vpoIds
    sendUpdateNumSelectedEvent()
  }

  reopen(id) {
    if (window.confirm('Are you sure you want to reopen VPO?')) {
      reopen(id, (err, res) => {
        if (err) {
          alert('There was an error when trying to reopen VPO')
        } else {
          alert('success')
        }
      })
    }
  }

  render() {
    let {visible, sliceNum, loadMore, results, numResults, visibleFields, column, direction, handleSort, repOptions} = this.props

    return (
      <Transition visible={visible} animation='fade' duration={1000}>
        <div>
          {
            results.length == 0
            ?
            <div style={{margin: '20px'}}>No vendor orders found</div>
            :
            <div className="heavyShadow" style={{marginTop: '20px', overflowX: 'scroll'}}>
              <Table sortable celled compact definition selectable={false}>
                <TableHeader labels={labels.filter(label => visibleFields.includes(label))}
                  column={column} direction={direction} handleSort={handleSort}
                />
                <Table.Body>
                  {
                    results.map((row, index) => {
                      let idText = row.id+'-'+row.cad_id
                      if (row.id == null || row.cad_id == null) {
                        idText = index
                      }

                      let isLocked = getIsLocked(row.ex_factory)
                      let isUnlocked = getIsUnlocked(row.ex_factory)

                      let tableRow = (
                        <Table.Row key={index}>
                          <Table.Cell style={{whiteSpace: 'nowrap'}}>
                            <div className="ui checkbox">
                              <input id={'vpo-checkbox-'+idText} type="checkbox" style={{marginRight: '10px'}}
                                onChange={this.vpoCheckboxOnChange.bind(null, row.id, row.cad_id, index)} className="vpo-checkbox"
                              />
                              <label for={'vpo-checkbox-'+idText} style={{cursor: 'default', marginRight: '10px'}}>
                                {row.refnumber == null ? '—' : row.refnumber}
                              </label>
                            </div>

                            <Popup content='Go to edit page' position='bottom left' size='mini'
                              trigger={
                                <a style={{cursor: 'pointer'}} target="_blank" href={"/#/vendor-orders/" + row.id + '/edit'}><Icon name='pencil'/></a>
                              }
                            />

                            {isLockedTooltip(isLocked, row.ex_factory)}
                            {isUnlockedTooltip(isUnlocked, row.ex_factory)}

                            {
                              !!row.qb_imported
                              ?
                              <Popup
                                size='mini'
                                content={'This VPO was imported from QB'}
                                position='bottom left'
                                trigger={
                                  <Icon name="download" style={stickyNoteStyle}/>
                                }
                              />
                              :
                              null
                            }

                          </Table.Cell>

                          {visibleFields.includes(labels[1]) ? <Table.Cell>{row.vponumber}</Table.Cell> : null}
                          {visibleFields.includes(labels[2]) ? <Table.Cell style={{whiteSpace: 'nowrap'}}>{row.style_number}</Table.Cell> : null}
                          {visibleFields.includes(labels[3]) ? imageTableCell(row) : null}
                          {
                            visibleFields.includes(labels[4])
                            ?
                            <Table.Cell>
                              {
                                row.factory_id == null
                                ?
                                '—'
                                :
                                row.factory_id.length == 4
                                ?
                                row.factory_id+' ('+factoryNames[row.factory_id]+')'
                                :
                                row.factory_id
                              }
                            </Table.Cell>
                            :
                            null
                          }
                          {visibleFields.includes(labels[5]) ? <Table.Cell>{row.buyer_name == null ? 'Pending' : row.buyer_name}</Table.Cell> : null}
                          {visibleFields.includes(labels[6]) ? <Table.Cell>{Number(row.qty).totalFormat()}</Table.Cell> : null}
                          {visibleFields.includes(labels[7]) ? <Table.Cell>${row.price}</Table.Cell> : null}
                          {visibleFields.includes(labels[8]) ? <Table.Cell style={{whiteSpace: 'nowrap'}}>{getStatusMarkup(row.status)} {row.status == "Cancelled" ? <a style={{fontSize: '10px', cursor: 'pointer', float: 'right', textDecoration: 'underline'}} onClick={this.reopen.bind(null, row.id)}>REOPEN</a> : null} {row.edited ? <span style={{fontSize: '10px', background: 'yellow', padding: '2px', border: '1px solid lightgrey', borderRadius: '2px'}}><Icon name='flag'/>EDITED on {row.edited_on}</span> : null}</Table.Cell> : null}
                          {visibleFields.includes(labels[9]) ? <Table.Cell><VpoStages vpo={row} /></Table.Cell> : null}
                          {visibleFields.includes(labels[10]) ? <Table.Cell>{row.cponumber == '' || row.cponumber == null ? '—' : row.cponumber}</Table.Cell> : null}

                          {visibleFields.includes(labels[11]) ? <Table.Cell style={{whiteSpace: 'nowrap'}}>{row.size}</Table.Cell> : null}
                          {visibleFields.includes(labels[12]) ? <Table.Cell>{removeLeadingZeros(row.created_date)}</Table.Cell> : null}
                          {visibleFields.includes(labels[13]) ? <Table.Cell>{displayDelivDate(row)}</Table.Cell> : null}
                          {visibleFields.includes(labels[14]) ? <Table.Cell>{row.ex_factory == null ? '—' : removeLeadingZeros(row.ex_factory)}</Table.Cell> : null}
                          {visibleFields.includes(labels[15]) ? <Table.Cell>{row.original_ex_factory == null ? '—' : removeLeadingZeros(row.original_ex_factory)}</Table.Cell> : null}
                          {visibleFields.includes(labels[16]) ? <Table.Cell>{row.loading_date == null ? '—' : removeLeadingZeros(row.loading_date)}</Table.Cell> : null}
                          {visibleFields.includes(labels[17]) ? <Table.Cell>{row.container_number == null ? '—' : row.container_number}</Table.Cell> : null}
                          {visibleFields.includes(labels[18]) ? <Table.Cell>{row.memo}</Table.Cell> : null}
                          {visibleFields.includes(labels[19]) ? <Table.Cell>{row.case_pack}</Table.Cell> : null}
                          {visibleFields.includes(labels[20]) ? <Table.Cell>{roundToPenny(Number(row.qty) / Number(row.case_pack))}</Table.Cell> : null}
                          {visibleFields.includes(labels[21]) ? <Table.Cell>{row.polibag}</Table.Cell> : null}
                          {visibleFields.includes(labels[22]) ? <Table.Cell>{row.fabric_weight}</Table.Cell> : null}
                          {visibleFields.includes(labels[23]) ? <Table.Cell>{row.fabric_content}</Table.Cell> : null}
                          {visibleFields.includes(labels[24]) ? <Table.Cell>{row.country == null ? '—' : row.country}</Table.Cell> : null}
                          {visibleFields.includes(labels[25]) ? <Table.Cell>{row.fob_rts == null ? '—' : removeLeadingZeros(row.fob_rts)}</Table.Cell> : null}
                          {visibleFields.includes(labels[26]) ? <Table.Cell>{row.asnx_factory == null ? '—' : removeLeadingZeros(row.asnx_factory)}</Table.Cell> : null}


                          {visibleFields.includes(labels[27]) ? <Table.Cell>{row.discount == null ? '—' : row.discount+'%'}</Table.Cell> : null}
                          {visibleFields.includes(labels[28]) ? <Table.Cell>${roundToPenny(getTotalDollar(row)).totalFormat()}</Table.Cell> : null}
                          {visibleFields.includes(labels[29]) ? <Table.Cell>{row.transaction_id == null ? '—' : row.transaction_id}</Table.Cell> : null}

                          {
                            visibleFields.includes(labels[30])
                            ?
                            <Table.Cell>
                              {row.amount_paid == null ? '—' : '$' + row.amount_paid.totalFormat()}
                            </Table.Cell>
                            :
                            null
                          }

                          {visibleFields.includes(labels[31]) ? <Table.Cell>{row.time_paid == null ? '—' : row.time_paid}</Table.Cell> : null}
                          {visibleFields.includes(labels[32]) ? <Table.Cell>{row.time_cancelled == null ? '—' : row.time_cancelled}</Table.Cell> : null}
                          {visibleFields.includes(labels[33]) ? <Table.Cell>{row.site_warehouse}</Table.Cell> : null}
                          {visibleFields.includes(labels[34]) ? <Table.Cell>{!!row.cad ? row.cad.brand : null}</Table.Cell> : null}
                          {visibleFields.includes(labels[35]) ? <Table.Cell>{!!row.cad ? row.cad.department : null}</Table.Cell> : null}
                          {visibleFields.includes(labels[36]) ? <Table.Cell>{!!row.cad ? row.cad.parent_category : null}</Table.Cell> : null}
                          {visibleFields.includes(labels[37]) ? <Table.Cell>{!!row.cad ? row.cad.category : null}</Table.Cell> : null}
                          {visibleFields.includes(labels[38]) ? <Table.Cell>{!!row.cad ? row.cad.color : null}</Table.Cell> : null}
                          {visibleFields.includes(labels[39]) ? fabricationTableCell(row) : null}
                          {visibleFields.includes(labels[40]) ? <Table.Cell>{!!row.cad ? row.cad.packs : null}</Table.Cell> : null}
                          {visibleFields.includes(labels[41]) ? <Table.Cell>{row.sales_rep_id == null ? '—' : repOptions.find(x => x.key == row.sales_rep_id).text}</Table.Cell> : null}
                        </Table.Row>
                      )

                      return pageLabel(index, tableRow)
                    })
                  }
                </Table.Body>
              </Table>
            </div>
          }
          {moreResults(sliceNum, numResults, loadMore)}
        </div>
      </Transition>
    )
  }
}

export default VendorOrdersTable
