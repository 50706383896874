import React, { Component } from 'react';
import $ from 'jquery';
import './ats-filter.css';
import { Card, Icon, Dropdown, Grid, Button, Input, Checkbox, Popup, Accordion, Label, Table} from 'semantic-ui-react'
import { connect } from 'react-redux'
import * as cadActions from '../../actions/cadActions'
import { bindActionCreators } from 'redux'
import 'rheostat/initialize';
import Rheostat from 'rheostat';
import {processSliderValues, processFutureATSSliderValues, getMax} from './process-slider-values.js'
import DisplayMinMax from './display-min-max.js'
import {isCustomer} from './is-role.js'

let setMinStyle = {padding: '3px 5px', marginLeft: '5px', marginTop: '5px'}

class AtsFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.updateOnHandValues = this.updateOnHandValues.bind(this)
    this.updateHoldQtyValues = this.updateHoldQtyValues.bind(this)
    this.updateAtsValues = this.updateAtsValues.bind(this)
    this.updateFutureAtsValues = this.updateFutureAtsValues.bind(this)
    this.updatePageValues = this.updatePageValues.bind(this)
    this.updateHoldAgeValues = this.updateHoldAgeValues.bind(this)
    this.setMinFutureATSto0 = this.setMinFutureATSto0.bind(this)
  }

  componentDidMount() {
    window.minOnHand = 1
    window.maxOnHand = getMax(this.props.orgId)
    window.minAts = 1
    window.maxAts = getMax(this.props.orgId)
    window.minFutureAts = isCustomer() ? 0 : -3100
    window.maxFutureAts = getMax(this.props.orgId)
    window.minPage = 0    //later change to 1 maybe?
    window.maxPage = 800
    window.minHoldAge = 0    //later change to 1 maybe
    window.maxHoldAge = window.defaultMaxHoldAge
    window.minHoldQty = 1
    window.maxHoldQty = getMax(this.props.orgId)
  }

  updateOnHandValues(sliderState) {
    let {min, max} = processSliderValues(sliderState, this.props.orgId)
    let {values} = sliderState

    window.minOnHand = values[0]
    window.maxOnHand = values[1] //(values[1] < 999 ? 999 : values[1])   //move max slider up so doesn't overlap
    $('#on-hand-min').text(min)
    $('#on-hand-max').text(max+(max == getMax(this.props.orgId) ? '+' : ''))

    clearTimeout(window.updateOnHandValuesSetTimeout)
    window.updateOnHandValuesSetTimeout = setTimeout(
      () => {
        this.props.updateOnHandState(min, max)
        if (!this.props.cadReducer.showOnHand) {
          this.props.actions.toggleHideableCheckbox('showOnHand')
        }
      }
    , 500)
  }

  updateHoldQtyValues(sliderState) {
    let {min, max} = processSliderValues(sliderState)
    let {values} = sliderState

    window.minHoldQty = values[0]
    window.maxHoldQty = (values[1] < 999 ? 999 : values[1])
    $('#hold-qty-min').text(min)
    $('#hold-qty-max').text(max+(max == getMax(this.props.orgId) ? '+' : ''))

    clearTimeout(window.updateHoldQtyValuesSetTimeout)
    window.updateHoldQtyValuesSetTimeout = setTimeout(
      () => {
        this.props.updateHoldQtyState(min, max)
        if (!this.props.cadReducer.showHold) {
          this.props.actions.toggleHideableCheckbox('showHold')
        }
      }
    , 500)
  }

  updateAtsValues(sliderState) {
    let {min, max} = processSliderValues(sliderState, this.props.orgId)
    let {values} = sliderState

    window.minAts = values[0]
    window.maxAts = values[1]  //(values[1] < 999 ? 999 : values[1])   //move max slider up so doesn't overlap
    $('#ats-min').text(min)
    $('#ats-max').text(max+(max == getMax(this.props.orgId) ? '+' : ''))

    clearTimeout(window.updateATSValuesSetTimeout)
    window.updateATSValuesSetTimeout = setTimeout(
      () => {
        this.props.updateATSState(min, max)
        if (!this.props.cadReducer.showATS) {
          this.props.actions.toggleHideableCheckbox('showATS')
        }
      }
    , 500)
  }

  updateFutureAtsValues(sliderState, timeout = 500) {
    let {min, max} = processFutureATSSliderValues(sliderState)
    let {values} = sliderState

    window.minFutureAts = values[0]
    window.maxFutureAts = values[1]
    if (min == -3000) {
      $('#future-ats-min').text(min+ ' or less')
    } else {
      $('#future-ats-min').text(min)
    }

    if (max == getMax(this.props.orgId)) {
      $('#future-ats-max').text(max+'+')
    } else {
      $('#future-ats-max').text(max)
    }

    clearTimeout(window.updateFutureAtsValuesSetTimeout)
    window.updateFutureAtsValuesSetTimeout = setTimeout(
      () => {
        this.props.updateFutureATSState(min, max)
        if (!this.props.cadReducer.showFutureATS) {
          this.props.actions.toggleHideableCheckbox('showFutureATS')
        }
      }
    , timeout)
  }

  setMinFutureATSto0() {
    let {maxFutureAts} = window
    if (maxFutureAts < 0) {maxFutureAts = getMax(this.props.orgId)}
    let sliderState = {values: [1, maxFutureAts]}
    this.updateFutureAtsValues(sliderState, 0)
  }

  updatePageValues(sliderState) {
    let {values} = sliderState
    let min = values[0]
    let max = values[1]

    $('#page-min').text(min)

    if (max == 800) {
      $('#page-max').text(max+'+')
    } else {
      $('#page-max').text(max)
    }

    window.minPage = min
    window.maxPage = max

    clearTimeout(window.updatePageValuesSetTimeout)
    window.updatePageValuesSetTimeout = setTimeout(
      () => {
        this.props.updatePageState(min, max)
        if (!this.props.cadReducer.showPage) {
          this.props.actions.toggleHideableCheckbox('showPage')
        }
      }
    , 500)
  }

  updateHoldAgeValues(sliderState) {
    let {values} = sliderState
    var min = values[0]
    var max = values[1]

    $('#hold-age-min').text(min+' days')
    $('#hold-age-max').text(max+' days')

    window.minHoldAge = min
    window.maxHoldAge = max

    clearTimeout(window.updateHoldAgeValuesSetTimeout)
    window.updateHoldAgeValuesSetTimeout = setTimeout(
      () => this.props.updateHoldAgeState(min, max)
    , 500)
  }

  render() {
    let {isAdmin, isSales, isWarehouse, isShowroom, isGuest, cadsToSearch} = this.props

    let isAdminOrSales = (isAdmin || isSales)

    return (
      <div>
        <Grid columns={4} stackable>
          <Grid.Row>
            {
              isGuest || isCustomer() ? null :
              <Grid.Column style={{paddingLeft: '40px', paddingRight: '40px'}}>
                <div style={{marginBottom: '10px'}}>
                  On Hand (Units)
                </div>
                <div style={window.freezeOnHandFilter ? {opacity: 0.5, pointerEvents: 'none'} : null}>
                  <Rheostat
                      min={1}
                      max={getMax(this.props.orgId)}
                      values={[window.minOnHand,window.maxOnHand]}
                      onValuesUpdated={this.updateOnHandValues}
                    />
                </div>
                <DisplayMinMax name='on hand' minInitialValue='0' maxInitialValue={'' + getMax(this.props.orgId) + '+'} />
              </Grid.Column>
            }

            {
              isWarehouse
              ?
              null
              :
              <Grid.Column style={window.freezeATSFilter ? {paddingLeft: '40px', paddingRight: '40px', cursor: 'not-allowed'} : {paddingLeft: '40px', paddingRight: '40px'}}>
                <div style={{marginBottom: '10px'}}>
                  ATS (Units)
                </div>
                <div style={window.freezeATSFilter ? {opacity: 0.5, pointerEvents: 'none'} : null}>
                  <Rheostat
                      min={1}
                      max={getMax(this.props.orgId)}
                      values={isCustomer() ? [window.minAts] : [window.minAts, window.maxAts]}
                      onValuesUpdated={this.updateAtsValues}
                    />
                </div>
                <DisplayMinMax name='ats' minInitialValue='0' maxInitialValue={'' + getMax(this.props.orgId) + '+'} />
              </Grid.Column>
            }

            {
              (isGuest && !this.props.cadReducer.showFutureATS) || isWarehouse
              ?
              null
              :
              <Grid.Column style={window.freezeFutureATSFilter ? {paddingLeft: '40px', paddingRight: '40px', cursor: 'not-allowed'} : {paddingLeft: '40px', paddingRight: '40px'}}>
                <div style={{marginBottom: '10px'}}>
                  Future ATS (Units)
                </div>
                <div style={window.freezeFutureATSFilter ? {opacity: 0.5, pointerEvents: 'none'} : null}>
                  <Rheostat
                      min={isCustomer() ? 0 : -3100}
                      max={getMax(this.props.orgId)}
                      values={isCustomer() ? [window.minFutureAts] : [window.minFutureAts,window.maxFutureAts]}
                      onValuesUpdated={this.updateFutureAtsValues}
                    />
                </div>
                <DisplayMinMax name='future ats' minInitialValue={isCustomer() ? '0' : '-3000 or less'} maxInitialValue={'' + getMax(this.props.orgId) + '+'} />
                {
                  isCustomer()
                  ?
                  null
                  :
                  <Button style={setMinStyle} onClick={this.setMinFutureATSto0}basic size='mini'>Set min to 0</Button>
                }
              </Grid.Column>
            }
            <Grid.Column style={{paddingLeft: '40px', paddingRight: '40px'}}>
              {isAdmin || isSales ? (
                <div>
                  <div style={{marginBottom: '15px'}}>
                    Page
                  </div>
                  <Rheostat
                      min={0}
                      max={800}
                      values={[window.minPage,window.maxPage]}
                      onValuesUpdated={this.updatePageValues}
                    />
                  <DisplayMinMax name='page' minInitialValue='0' maxInitialValue='800+' />
                </div>
              ) : null}
            </Grid.Column>
            <Grid.Column style={{paddingLeft: '40px', paddingRight: '40px'}}>
              {
                isAdmin || isSales
                ?
                <div style={this.props.cadReducer.cadStatus == 'Hold' ? null : {display: 'none'}}>
                  <div style={{marginBottom: '15px'}}>
                    Hold Age
                  </div>
                  <Rheostat
                      min={0}
                      max={800}
                      values={[window.minHoldAge,window.maxHoldAge]}
                      onValuesUpdated={this.updateHoldAgeValues}
                    />
                  <DisplayMinMax name='hold age' minInitialValue='0 days' maxInitialValue={window.defaultMaxHoldAge+' days'} />
                </div>
                :
                null
              }
            </Grid.Column>
            <Grid.Column style={{paddingLeft: '40px', paddingRight: '40px'}}>
              {
                isAdmin || isSales
                ?
                <div style={this.props.cadReducer.cadStatus == 'Hold' ? null : {display: 'none'}}>
                  <div style={{marginBottom: '15px'}}>
                    Hold Qty
                  </div>
                  <Rheostat
                      min={1}
                      max={getMax(this.props.orgId)}
                      values={[window.minHoldQty,window.maxHoldQty]}
                      onValuesUpdated={this.updateHoldQtyValues}
                    />
                  <DisplayMinMax name='hold qty' minInitialValue='0' maxInitialValue={'' + getMax(this.props.orgId) + '+'} />
                </div>
                :
                null
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cadActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AtsFilter)
