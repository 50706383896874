import React from 'react'
import {Message} from 'semantic-ui-react'

const bestSellerSuccess = (myState, bestSellerSuccessHandleDismiss, averageSellerSuccessHandleDismiss, poorSellerSuccessHandleDismiss) => {
  return (
    <React.Fragment>
      {myState.bestSellerSuccessVisible ? (
        <Message
          onDismiss={bestSellerSuccessHandleDismiss}
          icon='thumbs up outline'
          header='You have successfully marked the cads as best sellers'
        />
      ) : null}

      {myState.averageSellerSuccessVisible ? (
        <Message
          onDismiss={averageSellerSuccessHandleDismiss}
          icon='smile outline'
          header='You have successfully marked the cads as average sellers'
        />
      ) : null}

      {myState.poorSellerSuccessVisible ? (
        <Message
          onDismiss={poorSellerSuccessHandleDismiss}
          icon='thumbs down outline'
          header='You have successfully marked the cads as poor sellers'
        />
      ) : null}
    </React.Fragment>
  )
}

export {bestSellerSuccess}
