import $ from 'jquery'
import Utils from '../../modules/utils'
import {isCustomer} from '../cads/is-role'

const saveBestSeller = (bestOrPoor, myState, mySetState, myClearCadCard, myCadReducer) => {
  let sales_date = $('#mark-as-' + bestOrPoor + '-seller-date').val()

  if (sales_date == '') {
    alert('Please select a date!')
    return
  }

  if (!isCustomer() && ((myState.poorSellerAccount == '' && bestOrPoor == 'poor') || (myState.averageSellerAccount == '' && bestOrPoor == 'average') || (myState.bestSellerAccount == '' && bestOrPoor == 'best'))) {
    alert('Please select a contact!')
  } else {
    let profile = JSON.parse(sessionStorage.getItem('user'));
    let user_name = (profile.name != null ? profile.name : profile.email)
    let keys = Object.keys(window.cadIds);
    let {poorSellerAccount, bestSellerAccount, averageSellerAccount} = myState

    $("#mark-as-poor-seller-button, #mark-as-average-seller-button, #mark-as-best-seller-button").addClass('loading disabled');

    if (isCustomer()) {
      poorSellerAccount = myCadReducer.defaultAccount
      averageSellerAccount = myCadReducer.defaultAccount
      bestSellerAccount = myCadReducer.defaultAccount
    }

    let data = {
      cad_ids: keys,
      bestOrPoor: bestOrPoor,
      poorSellerAccount,
      averageSellerAccount,
      bestSellerAccount,
      user_name: user_name,
      sales_date: sales_date
    }

    Utils.post('best_seller', data, (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to save the ' + bestOrPoor + ' seller.')
      } else {
        $("#mark-as-poor-seller-button, #mark-as-best-seller-button, #mark-as-average-seller-button").removeClass('loading disabled');
        // your callback here
        if (bestOrPoor == 'best') {
          mySetState({bestSellerSuccessVisible: true, bestSellerModalOpen: false});
        } else if (bestOrPoor == 'average') {
          mySetState({averageSellerSuccessVisible: true, averageSellerModalOpen: false});
        } else {
          mySetState({poorSellerSuccessVisible: true, poorSellerModalOpen: false});
        }
        //increment total number
        //OLD - but doesn't update fast because of cache
        //this.search();

        let newCads = window.cads
        let numberKeys = keys.map(Number)

        for (let i=0;i<newCads.length;i++) {
          if (numberKeys.indexOf(newCads[i].id) > -1) {
            if (bestOrPoor == 'best') {
              newCads[i].best_seller_total += 1;
            } else if (bestOrPoor == 'average') {
              newCads[i].average_seller_total += 1;
            } else {
              newCads[i].poor_seller_total += 1;
            }
          }
        }

        window.cads = newCads
        myClearCadCard()
        mySetState({refreshCadCards: !myState.refreshCadCards})
      }
    })
  }
}

export {saveBestSeller}
