const getMax = (orgId) => {
    switch (orgId) {
    case 5:
      return 110
    default:
      return 11000
  }
}

const getFloors = (orgId) => {
  switch (orgId) {
    case 5:
      return [
        9,
        15,
        25,
        35,
        45,
        55,
        65,
        75,
        85,
        95,
        100,
        105
      ]
    default:
      return [
        999,
        1199,
        1599,
        2199,
        2799,
        3399,
        4599,
        4799,
        5999,
        7199,
        8399,
        9599
      ]
  }
}

const getVals = (orgId) => {
  switch (orgId) {
  case 5:
    return [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      15,
      25,
      35,
      45,
      55,
      65,
      75,
      85
    ]
  default:
    return [
      0,
      1,
      13,
      99,
      199,
      299,
      399,
      499,
      599,
      1199,
      1799,
      2399,
      3599,
      3799,
      4999,
      6199,
      7399,
      8599
    ]
  }
}

const getVals2 = (orgId) => {
  switch (orgId) {
  case 5:
    return [
      6,
      7,
      8,
      9,
      15,
      25,
      35,
      45,
      55,
      65,
      75,
      85
    ]
  default:
    return [
      12,
      198,
      598,
      1198,
      1798,
      2398,
      3598,
      3798,
      4998,
      6198,
      7398,
      8598
    ]
  }
}

const getCeils = (orgId) => {
    switch (orgId) {
    case 5:
      return [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        20,
        30,
        40,
        50,
        60,
        70,
        80,
        90
      ]
    default:
      return [
        1,
        199,
        399,
        499,
        599,
        699,
        799,
        899,
        999,
        1599,
        2199,
        2799,
        3999,
        4199,
        5399,
        6599,
        7799,
        9999
      ]
  }
}




const processSliderValues = (sliderState, orgId) => {
  let min = sliderState.values[0]

  let ceils = getCeils(orgId)
  let vals = getVals(orgId)
  let floors = getFloors(orgId)
  let vals2 = getVals2(orgId)

  if (min == ceils[0]) {
    min = vals[0];
  } else if (min <= ceils[1]) {
    min = vals[1];
  } else if (min <= ceils[2]) {
    min = vals[2];
  } else if (min <= ceils[3]) {
    min = vals[3];
  } else if (min <= ceils[4]) {
    min = vals[4];
  } else if (min <= ceils[5]) {
    min = vals[5];
  } else if (min <= ceils[6]) {
    min = vals[6];
  } else if (min <= ceils[7]) {
    min = vals[7];
  } else if (min <= ceils[8]) {
    min = vals[8];
  } else if (min <= ceils[9]) {
    min = vals[9];
  } else if (min <= ceils[10]) {
    min = vals[10];
  } else if (min <= ceils[11]) {
    min = vals[11];
  } else if (min <= ceils[12]) {
    min = vals[12];
  } else if (min <= ceils[13]) {
    min = vals[13];
  } else if (min <= ceils[14]) {
    min = vals[14];
  } else if (min <= ceils[15]) {
    min = vals[15];
  } else if (min <= ceils[16]) {
    min = vals[16];
  } else if (min <= ceils[17]) {
    min = vals[17];
  } else {
    min = Math.floor(min / 100) * 100 - 1; //17 23 35
  }

  var max = sliderState.values[1];
  if (max == getMax(orgId)) {
    //do nothing
  } else if (max <= floors[0] && vals2[0] > min) {
    max = vals2[0];
  } else if (max <= floors[1] && vals2[1] > min) {
    max = vals2[1];
  } else if (max <= floors[2] && vals2[2] > min) {
    max = vals2[2];
  } else if (max <= floors[3] && vals2[3] > min) {
    max = vals2[3];
  } else if (max <= floors[4] && vals2[4] > min) {
    max = vals2[4];
  } else if (max <= floors[5] && vals2[5] > min) {
    max = vals2[5];
  } else if (max <= floors[6] && vals2[6] > min) {
    max = vals2[6];
  } else if (max <= floors[7] && vals2[7] > min) {
    max = vals2[7];
  } else if (max <= floors[8] && vals2[8] > min) {
    max = vals2[8];
  } else if (max <= floors[9] && vals2[9] > min) {
    max = vals2[9];
  } else if (max <= floors[10] && vals2[10] > min) {
    max = vals2[10];
  } else if (max <= floors[11] && vals2[11] > min) {
    max = vals2[11];
  } //else if (max <= 10799) {
    //max = 9798;
  //}
  else {
    //max = Math.floor(max / 100) * 100 - 2;
    max = getMax(orgId)
  }

  return {min, max}
}

const processFutureATSSliderValues = (sliderState) => {
  var min = sliderState.values[0];

  if (min <= -3099) {
    min = -3000
  } else if (min <= -2499) {
    min = -2400
  } else if (min <= -1899) {
    min = -1800
  } else if (min <= -1299) {
    min = -1200
  } else if (min <= -699) {
    min = -600
  } else if (min <= -599) {
    min = -480
  } else if (min <= -499) {
    min = -360
  } else if (min <= -399) {
    min = -240
  } else if (min <= -199) {
    min = -100
  } else if (min <= 1) {
    min = 0;
  } else if (min <= 199) {
    min = 1;
  } else if (min <= 399) {
    min = 13;
  } else if (min <= 499) {
    min = 99;
  } else if (min <= 599) {
    min = 199;
  } else if (min <= 699) {
    min = 299;
  } else if (min <= 799) {
    min = 399;
  } else if (min <= 899) {
    min = 499;
  } else if (min <= 999) {
    min = 599;
  } else if (min <= 1599) {
    min = 1199;
  } else if (min <= 2199) {
    min = 1799;
  } else if (min <= 2799) {
    min = 2399;
  } else if (min <= 3999) {
    min = 3599;
  } else if (min <= 4199) {
    min = 3799;
  } else if (min <= 5399) {
    min = 4999;
  } else if (min <= 6599) {
    min = 6199;
  } else if (min <= 7799) {
    min = 7399;
  } else if (min <= 9999) {
    min = 8599;
  } else {
    min = Math.floor(min / 100) * 100 - 1; //17 23 35
  }

  var max = sliderState.values[1];
  if (max == 11000) {
    //do nothing

  } else if (max <= -1299) {
    max = -1200;
  } else if (max <= -699) {
    max = -600;
  } else if (max <= -599) {
    max = -480;
  } else if (max <= -499) {
    max = -360;
  } else if (max <= -399) {
    max = -240;
  } else if (max <= -199) {
    max = -100;
  } else if (max <= 0) {
    max = -1;

  } else if (max <= 198 && 0 > min) {
    max = 0;

  } else if (max <= 1199 && 198 > min) {
    max = 198;
  } else if (max <= 1599 && 598 > min) {
    max = 598;
  } else if (max <= 2199 && 1198 > min) {
    max = 1198;
  } else if (max <= 2799 && 1798 > min) {
    max = 1798;
  } else if (max <= 3399 && 2398 > min) {
    max = 2398;
  } else if (max <= 4599 && 3598 > min) {
    max = 3598;
  } else if (max <= 4799 && 3798 > min) {
    max = 3798;
  } else if (max <= 5999 && 4998 > min) {
    max = 4998;
  } else if (max <= 7199 && 6198 > min) {
    max = 6198;
  } else if (max <= 8399 && 7398 > min) {
    max = 7398;
  } else if (max <= 9599 && 8598 > min) {
    max = 8598;
  } //else if (max <= 10799) {
    //max = 9798;
  //}
  else {
    //max = Math.floor(max / 100) * 100 - 2;
    max = 11000;
  }

  return {min, max}
}

export {processSliderValues, processFutureATSSliderValues, getMax}
