let guestStatusOptions = [
  { key: 'Filter by Units', text: 'Filter by Units', value: 'Filter by Units', icon: 'cart plus', className: 'header' },
  { key: 'ATS', text: 'ATS', value: 'ATS' },
  { key: 'ATS or Future ATS', text: 'ATS or Future ATS', value: 'ATS or Future ATS', },
  { key: 'Future ATS', text: 'Future ATS', value: 'Future ATS' },
  { key: 'Filter by Orders', text: 'Filter by Orders', value: 'Filter by Orders', icon: 'dollar sign', className: 'header' },
  { key: 'PO', text: 'PO', value: 'PO' },
  { key: 'Hold', text: 'Hold', value: 'Hold' },
  { key: 'Worksheet', text: 'Worksheet', value: 'Worksheet' },
  { key: 'Shipped', text: 'Shipped', value: 'Shipped' },
  { key: 'Not Purchased', text: 'Not Purchased', value: 'Not Purchased' },
  { key: 'Filter by Best or Poor Seller', text: 'Filter by Best or Poor Seller', value: 'Filter by Best or Poor Seller', icon: 'thumbs up', className: 'header' },
  { key: 'Best Sellers', text: 'Best Sellers', value: 'Best Sellers' },
  { key: 'Average Sellers', text: 'Average Sellers', value: 'Average Sellers' },
  { key: 'Poor Sellers', text: 'Poor Sellers', value: 'Poor Sellers' },
]

let warehouseStatusOptions = [
  { key: 'Filter by Units', text: 'Filter by Units', value: 'Filter by Units', icon: 'cart plus', className: 'header', sort: '10' },
  { key: 'On Hand', text: 'On Hand', value: 'On Hand', sort: '11' },
  { key: 'Immediate', text: 'Immediate', value: 'Immediate', sort: '11' },
  { key: 'Future Stock', text: 'Future Stock', value: 'Future Stock', sort: '11' },
]

let customerOnlyOptions = ['Filter by Best Seller']

let cadStatusOptions = [
  { key: 'All', text: 'All', value: 'All', sort: '00' },
  { key: 'Sort', text: 'Sort', value: 'Sort', icon: 'sort', className: 'header', sort: '00' },
  { key: 'Stage 1: Brand Approval', text: 'Stage 1: Brand Approval', value: 'Stage 1: Brand Approval', icon: 'check', className: 'header', sort: '04' },
  // { key: 'Stage 2: PP Samples', text: 'Stage 2: PP Samples', value: 'Stage 2: PP Samples', icon: 'check', className: 'header', sort: '06' },
  // { key: 'Stage 3: TOP Samples', text: 'Stage 3: TOP Samples', value: 'Stage 3: TOP Samples', icon: 'check', className: 'header', sort: '08' },
  { key: 'Filter by Units', text: 'Filter by Units', value: 'Filter by Units', icon: 'cart plus', className: 'header', sort: '10' },
  { key: 'Filter by Orders', text: 'Filter by Orders', value: 'Filter by Orders', icon: 'dollar sign', className: 'header', sort: '12' },
  { key: 'Filter by Best Seller or Deals', text: 'Filter by Best Seller or Deals', value: 'Filter by Best Seller or Deals', icon: 'thumbs up', className: 'header', sort: '14' },
  { key: 'Filter by Best Seller', text: 'Filter by Best Seller', value: 'Filter by Best Seller', icon: 'thumbs up', className: 'header', sort: '14' },
  { key: 'Filter by Found Field', text: 'Filter by Found Field', value: 'Filter by Found Field', icon: 'search', className: 'header', sort: '16' },
  { key: 'Filter by Missing Field', text: 'Filter by Missing Field', value: 'Filter by Missing Field', icon: 'ban', className: 'header', sort: '18' },

  { key: 'On Hand', text: 'On Hand', value: 'On Hand', sort: '11' },
  { key: 'Immediate', text: 'Immediate', value: 'Immediate', sort: '11' },
  { key: 'Oversold', text: 'Oversold', value: 'Oversold', sort: '11' },
  //{ key: 'Cut to order', text: 'Cut to order', value: 'Cut to order' },
  { key: 'Future ATS', text: 'Future ATS', value: 'Future ATS', sort: '11' },
  { key: 'Future ATS w/ No ETA', text: 'Future ATS w/ No ETA', value: 'Future ATS w/ No ETA', sort: '11' },
  { key: 'Future ATS Confirmed', text: 'Future ATS Confirmed', value: 'Future ATS Confirmed', sort: '11' },
  { key: 'Future Stock', text: 'Future Stock', value: 'Future Stock', sort: '11' },
  { key: 'ATS or Future ATS', text: 'ATS or Future ATS', value: 'ATS or Future ATS', sort: '11' },
  { key: 'PO', text: 'PO', value: 'PO', sort: '13a' },
  { key: 'Hold', text: 'Hold', value: 'Hold', sort: '13b' },
  { key: 'Worksheet', text: 'Worksheet', value: 'Worksheet', sort: '13c' },
  { key: 'Shipped', text: 'Shipped', value: 'Shipped', sort: '13d' },
  { key: 'Not Purchased', text: 'Not Purchased', value: 'Not Purchased', sort: '13e' },
  //{ key: 'Inactive', text: 'Inactive', value: 'Inactive' },
  //{ key: 'Not approved', text: 'Not approved', value: 'Not approved' },
  { key: 'Best Sellers', text: 'Best Sellers', value: 'Best Sellers', sort: '15'},
  { key: 'Average Sellers', text: 'Average Sellers', value: 'Average Sellers', sort: '15'},
  { key: 'Poor Sellers', text: 'Poor Sellers', value: 'Poor Sellers', sort: '15' },
  { key: 'Missing Fabrication', text: 'Missing Fabrication', value: 'Missing Fabrication', sort: '05'},
  { key: 'Missing MSRP', text: 'Missing MSRP', value: 'Missing MSRP', sort: '05'},
  { key: 'Brand Approval Requested', text: 'Brand Approval Requested', value: 'Brand Approval Requested', sort: '05b'},
  { key: 'Brand Approved', text: 'Brand Approved', value: 'Brand Approved', sort: '05c' },
  { key: 'Brand Rejected', text: 'Brand Rejected', value: 'Brand Rejected', sort: '05c' },

  // { key: 'PP Samples Requested', text: 'PP Samples Requested', value: 'PP Samples Requested', sort: '07b'},
  // { key: 'PP Samples Approved', text: 'PP Samples Approved', value: 'PP Samples Approved', sort: '07c' },
  // { key: 'PP Samples Rejected', text: 'PP Samples Rejected', value: 'PP Samples Rejected', sort: '07c' },
  //
  // { key: 'TOP Samples Requested', text: 'TOP Samples Requested', value: 'TOP Samples Requested', sort: '09b'},
  // { key: 'TOP Samples Approved', text: 'TOP Samples Approved', value: 'TOP Samples Approved', sort: '09c' },
  // { key: 'TOP Samples Rejected', text: 'TOP Samples Rejected', value: 'TOP Samples Rejected', sort: '09c' },

  { key: 'ETA / Loading Date', text: 'ETA / Loading Date', value: 'ETA / Loading Date', sort: '17' },
  { key: 'No ETA / Loading Date', text: 'No ETA / Loading Date', value: 'No ETA / Loading Date', sort: '19' },
  { key: 'Error Syncing', text: 'Error Syncing', value: 'Error Syncing', sort: '19' },
  // { key: 'Error Syncing and Reviewed', text: 'Error Syncing and Reviewed', value: 'Error Syncing and Reviewed', sort: '19' },
  { key: 'No Image', text: 'No Image', value: 'No Image', sort: '19' },
  { key: 'No Image w/ Future Stock', text: 'No Image w/ Future Stock', value: 'No Image w/ Future Stock', sort: '19' },
  { key: 'Image', text: 'Image', value: 'Image', sort: '17' },
  { key: 'No Sizes', text: 'No Sizes', value: 'No Sizes', sort: '19' },
  { key: 'Sizes', text: 'Sizes', value: 'Sizes', sort: '17' },
  { key: 'ATS', text: 'ATS', value: 'ATS', sort: '11' },
  { key: 'Deals', text: 'Deals', value: 'Deals', sort: '15'},
  { key: 'Sort by Created', text: 'Sort by Created', value: 'Sort by Created', sort: '01' },
  { key: 'Gift with Purchase', text: 'Gift with Purchase', value: 'Gift with Purchase', sort: '17' },
  { key: 'Mexico Customer', text: 'Mexico Customer', value: 'Mexico Customer', sort: '17' },
  { key: 'FACTORY-RTS', text: 'FACTORY-RTS', value: 'FACTORY-RTS', sort: '17' },
  { key: 'Pending PI', text: 'Pending PI', value: 'Pending PI', sort: '17' },
  { key: 'Case Pack', text: 'Case Pack', value: 'Case Pack', sort: '17' },
  { key: 'Container #', text: 'Container #', value: 'Container #', sort: '17' },
  // { key: 'Container # w/ Updated Dates', text: 'Container # w/ Updated Dates', value: 'Container # w/ Updated Dates', sort: '17' },
  { key: 'Updated Dates from VPO in ERP', text: 'Updated Dates from VPO in ERP', value: 'Updated Dates from VPO in ERP', sort: '17' },
  { key: 'Factory', text: 'Factory', value: 'Factory', sort: '17' },
  { key: 'Do Not Sell', text: 'Do Not Sell', value: 'Do Not Sell', sort: '11b' },
  { key: 'Long Style Number', text: 'Long Style Number', value: 'Long Style Number', sort: '17' },
  { key: 'No Long Style Number', text: 'No Long Style Number', value: 'No Long Style Number', sort: '19' },
  { key: 'No Long Style Number w/ Future Stock', text: 'No Long Style Number w/ Future Stock', value: 'No Long Style Number w/ Future Stock', sort: '19' },
];

cadStatusOptions.sort(function(a,b) {
  if (a.sort+a.key > b.sort+b.key) return 1;
  if (b.sort+b.key > a.sort+a.key) return -1;

  return 0;
});

let statusOptions = cadStatusOptions.filter(x => !customerOnlyOptions.includes(x.key))

export {guestStatusOptions, customerOnlyOptions, cadStatusOptions, warehouseStatusOptions, statusOptions}
