import React, { Component } from 'react'
import {Dimmer, Loader, Button, Icon, Input} from 'semantic-ui-react'
import Utils from './modules/utils'
import $ from 'jquery'
import VendorOrdersTable from './components/vendor-orders/vendor-orders-table.js'
import VendorOrdersFilters from './components/vendor-orders/vendor-orders-filters.js'
import {getVendorOrdersOptions, getCadsOptions, buyerOptions, repOptions} from './components/vendor-orders/options.js'
import filterVendorOrders from './components/vendor-orders/filter.js'
import copyCadToVendorOrders from './components/vendor-orders/copy-cad-to-vendor-orders.js'
import SearchInput from './components/vendor-orders/search-inputs.js'
import BulkButtons from './components/vendor-orders/bulk-buttons.js'
import VisibleFields from './components/vendor-orders/visible-fields.js'
import {labels} from './components/vendor-orders/labels.js'
import VpoTotals from './components/vendor-orders/vpo-totals.js'
import {vpoSort} from './components/vendor-orders/vpo-sort.js'
import {filtersButton} from './components/prints/filters-button'
import {getArchivedVpos} from './components/vendor-orders/get-archived-vpos'
import {showScrollToTop} from './components/vendor-orders/show-scroll-to-top'
import {sliceAdd} from './components/vendor-orders/slice-add'
import {getCadsCache} from './components/cads/cache/get'
import {getContacts} from './components/new-vpo-page/get-contacts.js'
import {Auth0Context} from './contexts/auth-context'
import {sortMapNameOptions} from './components/purchase-orders/sort-map-id-name-options.js'
import './components/cads/scroll-to-top.css'

const clearFiltersState = {
  buyer: [],
  factory: [],
  status: [],
  sliceNum: sliceAdd,
  brand: [],
  category: [],
  division: [],
  searchString: '',
  fromDate: null,
  toDate: null,
  statuses: [],
  payments: [],
  rep: []
}

const defaultVisibleFields = labels.slice(0, 9).filter(x => x != 'IMAGE')

class VendorOrdersPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      cadLoading: true,
      buyer: [],
      factoryOptions: [],
      factory: [],
      statusOptions: [],
      status: [],
      payments: [],
      showFilters: false,
      showTable: false,
      sliceNum: sliceAdd,
      brand: [],
      brandOptions: [],
      category: [],
      categoryOptions: [],
      division: [],
      divisionOptions: [],
      searchString: '',
      fromDate: null,
      toDate: null,
      showBulk: true,
      statuses: [],
      visibleFields: JSON.parse(JSON.stringify(defaultVisibleFields)),
      column: 'REF #',
      direction: 'ascending',
      accountOptions: null,
      fabricationOptions: null,
      showEditedOn: false,
      rep: []
    }

    this.toggleFilters = this.toggleFilters.bind(this)
    this.successCallback = this.successCallback.bind(this)
    this.filterVendorOrders = this.filterVendorOrders.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.cadsSuccessCallback = this.cadsSuccessCallback.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.updateSearchString = this.updateSearchString.bind(this)
    this.toggleBulk = this.toggleBulk.bind(this)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.selectDefault = this.selectDefault.bind(this)
    this.selectNone = this.selectNone.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.getContacts = this.getContacts.bind(this)
    this.setOptions = this.setOptions.bind(this)
    this.showTableCallback = this.showTableCallback.bind(this)
    this.toggleShowEditedOn = this.toggleShowEditedOn.bind(this)
  }

  componentDidMount() {
    window.vpoIds = {}
    window.vpoStages = {}
    window.numberOfApiSuccess = 0
    window.scrollTo(0, 0)
    this.refreshVendorOrders()

    getArchivedVpos(this.showTableCallback)
    showScrollToTop()
    this.getContacts()
    this.setOptions()
  }

  setOptions() {
    let {customer} = this.context
    if (customer == null) {
      setTimeout(this.setOptions, 500)
    } else {
      getCadsCache(customer.id, this.cadsSuccessCallback)
      let fabricationOptions = sortMapNameOptions(customer.fabrications)
      this.setState({fabricationOptions, repOptions: repOptions(customer)})
    }
  }

  getContacts() {
    getContacts((accountOptions) => {
      this.setState({accountOptions})
    })
  }

  refreshVendorOrders() {
    Utils.get('vendor_orders/', (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to get vendor orders.')
      } else {
        if (res.data.success) {
          this.successCallback(res.data.data)
        }
      }
    })
  }

  showTableCallback() {
    copyCadToVendorOrders()
    let {brandOptions, categoryOptions, divisionOptions} = getCadsOptions()
    this.setState({brandOptions, categoryOptions, divisionOptions})
    setTimeout(() => this.setState({showTable: true}), 0)
  }

  successCallback(data) {
    window.vendorOrders = JSON.parse(data).filter(x => !['NO ITEM', 'FREIGHT-OCEAN'].includes(x.style_number))
    let {factoryOptions, statusOptions} = getVendorOrdersOptions()
    this.setState({loading: false, factoryOptions, statusOptions})

    window.numberOfApiSuccess += 1
    if (window.numberOfApiSuccess == 3) {this.showTableCallback()}
  }

  cadsSuccessCallback(cads) {
    window.cads = cads
    this.setState({cadLoading: false})

    window.numberOfApiSuccess += 1
    if (window.numberOfApiSuccess == 3) {this.showTableCallback()}
  }

  fieldOnChange(event, {value, name}) {
    let newState = {sliceNum: sliceAdd}
    newState[name] = value
    this.setState(newState)
    this.filterVendorOrders()
  }

  toggleFilters() {
    this.setState({showFilters: !this.state.showFilters})
  }

  scrollToTop() {
    window.scrollTo(0,0)
    $('#scroll-to-top').hide()
  }

  filterVendorOrders() {
    setTimeout(() => {
      let statuses = filterVendorOrders(this.state)
      this.setState({statuses})
      this.forceUpdate()
    }, 0)
  }

  loadMore() {
    this.setState({sliceNum: this.state.sliceNum + sliceAdd})
  }

  updateSearchString(value) {
    let name = 'searchString'
    this.fieldOnChange(null, {value, name})
  }

  toggleBulk() {
    this.setState({showBulk: !this.state.showBulk})
  }

  toggleCheckbox(label) {
    let {visibleFields} = this.state

    if (visibleFields.includes(label)) {
      visibleFields = visibleFields.filter( el => el != label)
    } else {
      visibleFields.push(label)
    }

    this.setState({visibleFields})
  }

  selectAll() {
    this.setState({visibleFields: labels.slice(0)})
  }

  selectDefault() {
    this.setState({visibleFields: JSON.parse(JSON.stringify(defaultVisibleFields))})
  }

  selectNone() {
    this.setState({visibleFields: labels.slice(0, 1)})
  }

  handleSort(field) {
    let {direction, column} = this.state

    let sameColumn = column == field

    if (sameColumn) {
      this.setState({
        direction: direction === 'ascending' ? 'descending' : 'ascending'
      })
    } else {
      this.setState({
        column: field,
        direction: 'ascending'
      })
    }
  }

  clearFilters() {
    this.setState(clearFiltersState)
    $('#vpo-bulk-search').val('')
  }

  toggleShowEditedOn() {
    let value = !this.state.showEditedOn
    let name = 'showEditedOn'
    this.fieldOnChange(null, {value, name})
  }

  render() {
    let {
      loading, cadLoading, buyer, factoryOptions, factory, statusOptions, status, showFilters, showTable, sliceNum,
      brandOptions, categoryOptions, divisionOptions, brand, category, division, searchString, fromDate, toDate,
      showBulk, statuses, visibleFields, column, direction, payments, accountOptions, fabricationOptions,
      showEditedOn, repOptions, rep
    } = this.state

    if (loading) {
      return(
        <Dimmer active>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
      )
    }

    let {vendorOrders, filteredVendorOrders} = window
    let usingFilters = factory.length || status.length || payments.length || buyer.length || brand.length || division.length || category.length || searchString != '' || fromDate != null || toDate != null || showEditedOn || rep.length
    let results = usingFilters ? filteredVendorOrders : vendorOrders
    let numResults = 0
    if (results == null) {
      results = []
    } else {
      numResults = results.length
    }

    return (
      <div style={{padding: '50px 60px', paddingTop: '40px'}}>
        {filtersButton(showFilters, this.toggleFilters)}

        <BulkButtons results={results} visibleFields={visibleFields} clearFilters={this.clearFilters}
          accountOptions={accountOptions} fabricationOptions={fabricationOptions}
          repOptions={repOptions}
        />

        <VendorOrdersFilters showFilters={showFilters}
          statusOptions={statusOptions}
          status={status}
          factoryOptions={factoryOptions}
          factory={factory}
          buyerOptions={buyerOptions}
          buyer={buyer}
          brandOptions={brandOptions}
          brand={brand}
          categoryOptions={categoryOptions}
          category={category}
          divisionOptions={divisionOptions}
          division={division}
          fieldOnChange={this.fieldOnChange}
          cadLoading={cadLoading}
          payments={payments}
          showEditedOn={showEditedOn}
          toggleShowEditedOn={this.toggleShowEditedOn}
          repOptions={repOptions}
          rep={rep}
        />

        <VisibleFields toggleCheckbox={this.toggleCheckbox} visibleFields={visibleFields}
          selectAll={this.selectAll} selectDefault={this.selectDefault} selectNone={this.selectNone}
        />

        <SearchInput updateSearchString={this.updateSearchString}
          showBulk={showBulk} toggleBulk={this.toggleBulk}
          statuses={statuses} placeholder="Search: REF # , PO #, Style # or Transaction ID"
        />

        <VpoTotals results={results} />

        <VendorOrdersTable visible={showTable}
          sliceNum={sliceNum}
          loadMore={this.loadMore}
          numResults={numResults}
          results={vpoSort(results, column, direction).slice(0, sliceNum)}
          visibleFields={visibleFields}
          column={column} direction={direction} handleSort={this.handleSort}
          repOptions={repOptions}
        />

        <div id="scroll-to-top-corner-buttons">
          <button id="scroll-to-top" className="ui basic button" onClick={this.scrollToTop}><Icon name='arrow up' /> Back to top</button>
        </div>
      </div>
    )
  }
}

export default VendorOrdersPage
