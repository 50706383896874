import React, { Component } from 'react';
import $ from 'jquery';
import './ViewCadPage.css';

import { Table, Grid, Button, Menu, Header, Image, Tab, List, Label, Message, Icon} from 'semantic-ui-react'

import { connect } from 'react-redux';
import * as cadActions from './actions/cadActions';
import { bindActionCreators } from 'redux';
import {atsDetailText} from './components/cads/process-ats-detail.js'
import ValidateUnits from './components/view-cad/validate-units.js'
import {canEditCads} from './components/custom-access/can'

class ViewCadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styleNumber: '',
      cadCreator: '',
      cadCreateDate: '',
      imageUploadDate: '',
      filename: '',
      logMessages: []
    };

    this.getCad = this.getCad.bind(this);
    this.updateCad = this.updateCad.bind(this);
    this.editCad = this.editCad.bind(this);
  }

  componentDidMount() {
    this.getCad(this.props.id)
  }

  getCad(cad_id) {
    $.get(process.env.REACT_APP_API_URL + '/api/cads/' + cad_id, function (result) {
      if (!!result.not_using) {
        alert('This cad is not in use!')
      } else {
        this.updateCad(result);
        this.setState({logMessages: result.logs, cadCreator: result.user_name})
      }
    }.bind(this))
  }

  updateCad(result2) {
    var result = JSON.parse(result2.cad).data.attributes;

    let {future_ats, future_stock} = result

    let available = result.available;
    if (available != null && available < 0) {
      available = 0;
    }

    let on_hand = Number(result.on_hand)
    if (typeof available === "number" && available > on_hand) {
      available = on_hand
    }

    let color = result.color;
    if (color == null) {
      color = '';
    } else if (color[0] == '[') {
      color = JSON.parse(color).join(', ')
    }

    let status = result.availability;
    if (status == "Immediate") {
      status = "Active";
    }

    this.setState({
      styleNumber: result.style_number,
      longStyleNumber: result.long_style_number,
      cadCreateDate: result.date_created,
      imageUploadDate: result.image_uploaded,
      filename: result.filename,
      available: available,
      atsDetail: result.ats_detail,
      future_stock: future_stock,
      future_ats: future_ats,
      category: result.category,
      brand: result.brand,
      color: color,
      fabrication: result.fabrication,
      at: result.at,
      description: result.name,
      earliest_delivery_date: result.earliest_delivery_date,
      earliest_ex_factory_date: result.ex_factory_date,
      earliest_vendor_ponumber: result.vendor_ponumber,
      status: status,
      sizes: result.sizes,
      division: result.department,
      season: result.season,
      sub2: result.child_category,
      packs: result.packs,
      latest_delivery_date: result.latest_delivery_date,
      parent_category: result.parent_category,
      page: result.age,
      loading_date: result.loading_date,
      margins: result.margin,
      old_s3_bucket: result.old_s3_bucket,
      country: result.country,
      case_pack: result.case_pack,
      sites: result.sites,
      locations: result.locations,
      containerNumber: result.container_number
    })
  }

  editCad() {
    window.location = '/#/edit-cad/' + this.props.id
  }

  logMessageRow(log, index) {
    if (!window.isAdmin && log.message.indexOf('margin') != -1) {
      return <div key={index}></div>
    } else {
      return (
        <div key={index} style={{marginBottom: '10px'}}>
          <Message>
              <Message.Header>{log.user_name} &nbsp; @ &nbsp; {log.date_string}</Message.Header><pre>{log.message}</pre>
            </Message>
        </div>
      );
    }
  }

  render() {
    let {sites, locations, atsDetail, containerNumber, userName} = this.state
    let image_url
    let s3BucketName = this.state.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME

    if (this.state.filename == null || this.state.filename == '') {
      image_url = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png';
    } else {
      image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + this.state.filename;
    }

    let role = sessionStorage.getItem('currentUserRole')
    let isAdmin = ['admin','superadmin','finance'].includes(role)
    window.isAdmin = isAdmin;
    let isSales = ['sales','sales_assistant','inventory'].includes(role)
    let isDesign = role == 'design'
    let isProduction = role == 'production'

    let earliest_delivery_date;
    if (this.state.earliest_delivery_date != null) {
      earliest_delivery_date = this.state.earliest_delivery_date.split('T')[0];
    }

    if (this.state.loading_date != null) {
      earliest_delivery_date = 'ETA: ' + this.state.loading_date;
    }

    let latest_delivery_date;//DONT THINK USING
    if (this.state.latest_delivery_date != null) {
      latest_delivery_date = this.state.latest_delivery_date.split('T')[0];
    }

    const panes = [
      { menuItem: 'Details', render: () => <Tab.Pane>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <List divided selection>
                <List.Item>
                  <Label horizontal>
                    Description
                  </Label>
                  {this.state.description}
                </List.Item>
                <List.Item>
                  <Label horizontal>
                    Fabrication
                  </Label>
                  {this.state.fabrication}
                </List.Item>
                <List.Item>
                  <Label horizontal>
                    At
                  </Label>
                  {this.state.at}
                </List.Item>
                <List.Item>
                  <Label horizontal>
                    Sizes
                  </Label>
                  {this.state.sizes}
                </List.Item>
                <List.Item>
                  <Label horizontal>Brand</Label>
                  {this.state.brand}
                </List.Item>
                <List.Item>
                  <Label horizontal>Division</Label>
                  {this.state.division}
                </List.Item>
                <List.Item>
                  <Label horizontal>Sub-Category</Label>
                  {this.state.category}
                </List.Item>
                <List.Item>
                  <Label horizontal>Packs</Label>
                  {this.state.packs}
                </List.Item>
                <List.Item>
                  <Label horizontal>QTY per Case Pack</Label>
                  {this.state.case_pack}
                </List.Item>
                <List.Item>
                  <Label horizontal>Earliest Delivery Date</Label>
                  {earliest_delivery_date}
                </List.Item>
                {latest_delivery_date != null ? (
                  <List.Item>
                    <Label horizontal>Latest Delivery Date</Label>
                    {latest_delivery_date}
                  </List.Item>
                ) : null}
                <List.Item>
                  <Label horizontal>Earliest Ex-Factory Date</Label>
                  {this.state.earliest_ex_factory_date}
                </List.Item>
                <List.Item>
                  <Label horizontal>Earliest Vendor PO#</Label>
                  {this.state.earliest_vendor_ponumber}
                </List.Item>
                <List.Item>
                  <Label horizontal>Country of Origin</Label>
                  {this.state.country}
                </List.Item>
                <List.Item>
                  <Label horizontal>Site</Label>
                  {sites == null ? null : this.state.sites.join(', ')}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={8}>
              <List divided selection>
                <List.Item>
                  <Label horizontal>
                    Style Number
                  </Label>
                  {this.state.styleNumber}
                </List.Item>
                <List.Item>
                  <Label horizontal>
                    Long Style Number
                  </Label>
                  {this.state.longStyleNumber}
                </List.Item>
                <List.Item>
                  <Label horizontal>
                    Color
                  </Label>
                  {this.state.color}
                </List.Item>
                <List.Item>
                  <Label horizontal>
                    Status
                  </Label>
                  {this.state.status}
                </List.Item>
                <List.Item>
                  <Label horizontal>Season</Label>
                  {this.state.season}
                </List.Item>
                <List.Item>
                  <Label horizontal>Category</Label>
                  {this.state.parent_category}
                </List.Item>
                <List.Item>
                  <Label horizontal>Sub2-Category</Label>
                  {this.state.sub2}
                </List.Item>
                <List.Item>
                  <Label horizontal>ATS</Label>
                  {this.state.available}<br/>
                  {
                    atsDetail == null
                    ?
                    null
                    :
                    <div style={{padding: '5px', marginTop: '5px', border: '2px solid red'}}>
                      {atsDetailText(atsDetail)}<br/>
                      <small style={{color: 'red', fontWeight: 'bold', fontSize: '75%'}}><Icon name='warning sign'/> ATS by site is only an estimate</small>
                    </div>
                  }
                </List.Item>
                <List.Item>
                  <Label horizontal>Future Stock</Label>
                  {this.state.future_stock}
                </List.Item>
                <List.Item>
                  <Label horizontal>Future ATS</Label>
                  {this.state.future_ats}
                </List.Item>
                {isAdmin || isSales ? (
                  <List.Item>
                    <Label horizontal>Page</Label>
                    {this.state.page}
                  </List.Item>
                ) : null}
                {isAdmin ? (
                  <List.Item>
                    <Label horizontal>Margins</Label>
                    {this.state.margins}
                  </List.Item>
                ) : null}

                <List.Item>
                  <Label horizontal>Container #</Label>
                  {containerNumber == null ? '—' : containerNumber.split('@@@').join(', ')}
                </List.Item>

              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane> }
    ]

    panes.push({ menuItem: 'Log of Changes', render: () => <Tab.Pane>{this.state.logMessages.map(this.logMessageRow)}</Tab.Pane> });

    panes.push(
      {
        menuItem: 'Validate Units',
        render: () => <Tab.Pane><ValidateUnits cadId={this.props.id}/></Tab.Pane>
      }
    )

    return (
      <div style={{padding: '50px 60px'}}>
        <h1 style={{display: 'inline-block', marginTop: '25px', marginBottom: '30px'}}>View cad #{this.props.id}</h1>
        {isAdmin || canEditCads() ? (
          <Button basic style={{position: 'relative', bottom: '5px', marginLeft: '30px'}} onClick={this.editCad}>Edit</Button>
        ) : null}

        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Style Number</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Image Uploaded</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>{this.state.styleNumber}</Table.Cell>
              <Table.Cell><div dangerouslySetInnerHTML={{__html: this.state.cadCreateDate}}></div></Table.Cell>
              <Table.Cell>{this.state.cadCreator}</Table.Cell>
              <Table.Cell><div dangerouslySetInnerHTML={{__html: this.state.imageUploadDate}}></div></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <br/><br/>

        <Grid columns={2} stackable style={{minHeight: '300px'}}>
          <Grid.Row>
            <Grid.Column width={11}>
              <Tab id={"disable-some-items" + (isAdmin || isSales ? '-admin' : '')} panes={panes} />
            </Grid.Column>
            <Grid.Column width={5} style={{paddingTop: '42px'}}>
              <Image src={image_url} size='big' centered bordered style={{padding: '20px', maxHeight: '600px', width: 'auto', border: '1px solid rgb(225,225,225)', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 10px -2px'}}  />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <br/><br/><br/><br/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
 ...state
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cadActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCadPage)
