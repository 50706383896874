import                 './index.css'
import React      from 'react'
import {
          Accordion,
          Icon,
          Label,
          Table,
          Button,
          Checkbox,
          Form,
          Modal,
          Item,
          Message,
          Popup,
          Header
       }                  from 'semantic-ui-react'
import _                  from 'lodash'
import Utils              from './../../modules/utils'
import UserAPI            from './../../modules/api/user_api'
import CustomerAPI        from './../../modules/api/customer_api'
import logo               from './../../assets/svg/ihl-logo.svg'
import {LoadingParagraph} from './../ui/loading-paragraph'
import {Auth0Context}     from './../../contexts/auth-context'
import UserForm           from './user-form'
import CompanyForm        from './company-form'
import CompanySettings    from './company-settings'
import GoogleIntegration  from './google_integration'
import SimpleTable        from './../ui/simple-table'
import {checkAdminPin} from '../admin/pin.js'
import {customAccessModal} from '../custom-access/modal.js'
import {getCustomAccessData} from '../custom-access/get.js'
import {saveCustomAccessData, booleanFields, stringFields} from '../custom-access/save.js'
import {quickFixAllBoysAllGirls} from '../../reducers/quickFixAllBoysAllGirls.js'
import {sortStatus} from '../custom-access/sort-status.js'
import {getQuickbooksAccountNames} from '../custom-access/get-quickbooks-account-names'
import {sortMapOptions} from '../purchase-orders/sort-map-id-name-options'

class Organizations extends React.Component
{
  static contextType = Auth0Context;

  constructor(props)
  {
    super(props)
    this.state =
    {
      loading         : true,
      companies       : [],
      currentCompany  : {},
      currentCompanyId: -1,
      customAccessModal: false,
      customAccessData: null,
      newUserModal    : false,
      currentUser     : null,
      notes: [],
      users: [],
      active: false,
      newCompanyModal : false,
      settingsModal   : false,
      subsettingsModal: false,
      correctPin: true,
      quickbooksAccountNameOptions: []
    }
    this.setPinTrue = this.setPinTrue.bind(this)
    this.setPinFalse = this.setPinFalse.bind(this)
  }

  setPinTrue() {
    this.setState({correctPin: true})
  }

  setPinFalse() {
    this.setState({correctPin: false})
  }

  componentDidMount() {
    this.fetchCompanies()
    this.fetchQuickbooksAccountNames()

    if (window.disableAdminPin) {
      this.setPinTrue()
    } else {
      window.addEventListener('set-admin-pin-true', this.setPinTrue)
      window.addEventListener('set-admin-pin-false', this.setPinFalse)
      checkAdminPin()
    }
  }

  fetchCompanies = () =>
  {
    var { currentCompanyId, companies } = this.state
    let callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        companies        = data.customers
        currentCompanyId = companies[0].id
        let company     = companies.find(comp => comp.id === currentCompanyId)
        let companyUsers = company.users
        this.setState({ currentCompanyId, companies, companyUsers, loading: false })
      }
    }

    CustomerAPI.getAll(callback)
  }

  activateCompanyTab = (event, index) =>
  {
    var { currentCompanyId } = this.state
    var newIndex = currentCompanyId === index ? -1 : index
    this.setState({ currentCompanyId: newIndex })
  }

  openNewUserForm = (e) =>
  {
    e.stopPropagation()
    this.setState({ newUserModal: true, currentUser: null })
  }

  closeNewUserForm = (event, data=null) =>
  {
    var { companies, currentCompanyId } = this.state
    if (data && data.user && data.user.id)
    {
      var company = companies.find(comp => comp.id === currentCompanyId)
      Utils.addOrUpdateArray(company.users, data.user)
    }
    this.setState({ newUserModal: false, companies })
  }

  onSelectUser = (e, user) =>
  {
    var _user = this.context.user
    if (_user.id === user.id)  return

    var { companies, currentCompanyId } = this.state
    if (user && user.id)
    {
      var company     = companies.find(comp => comp.id === currentCompanyId)
      var currentUser = company.users.find(u => u.id === user.id)

      this.setState({ newUserModal: true, currentUser })
    }
  }

  destroyUser = (e, company, user) =>
  {
    e.preventDefault()
    e.stopPropagation()
    var confirm = window.confirm(`Are you sure! you want to remove user "${user.email}"`)

    if (confirm && user.id && company.id)
    {
      this.setState({ loading: true })
      let callback = (error, data) =>
      {
        if (error)
        {
          this.setState({ loading: false })
        }
        else
        {
          _.remove(company.users, (u) => u.id === user.id)
          this.setState({ loading: false })
        }
      }

      UserAPI.delete(user.id, callback, company.id)
    }
  }

  openNewCompanyForm = (event) =>
  {
    this.setState({ newCompanyModal: true })
  }

  closeNewCompanyForm = (event, data=null) =>
  {
    var { companies, currentCompanyId } = this.state
    if (data && data.customer && data.customer.id)
    {
      Utils.addOrUpdateArray(companies, data.customer)
      currentCompanyId = data.customer.id
    }
    this.setState({ newCompanyModal: false, companies, currentCompanyId })
  }

  changeCompanyStatus = (e, company) =>
  {
    e.preventDefault()
    e.stopPropagation()
    var status = company.status == 'active' ? 'inactive' : 'active'

    var confirm = window.confirm(`Are you sure you want to "${status == 'inactive' ? 'deactivate' : 'activate'}" the organization?`)

    if (confirm)
    {
      this.setState({ loading: true })
      var { companies } = this.state

      let callback = (error, data) =>
      {
        if (error)
        {
          console.error(error)
        }
        else
        {
          var compIndex = companies.findIndex(c => c.id === company.id)
          if (compIndex !== -1) companies[compIndex] = data.customer
          this.setState({ companies, loading: false })
        }
      }

      CustomerAPI.update(company.id, {customer: {status: status}}, callback)
    }
  }

  deleteOrganization = (e, company) =>
  {
    e.preventDefault()
    e.stopPropagation()

    var confirm = window.confirm(`Are you sure? you want to delete "${company.name}"`)

    if (confirm)
    {
      this.setState({ loading: true })
      var { companies } = this.state

      let callback = (error, data) =>
      {
        if (error)
        {
          console.error(error)
        }
        else
        {
          _.remove(companies, (c) => c.id === company.id)
          this.setState({ companies, loading: false })
        }
      }

      CustomerAPI.delete(company.id, callback)
    }
  }

  openSettingsModal = (e, company) =>
  {
    e.stopPropagation();
    this.setState({settingsModal: true, currentCompany: company})
  }

  closeSettingsModal = () =>
  {
    this.setState({settingsModal: false})
  }

  customAccessFieldOnChange = (event, {value, name, checked}) => {
    let {customAccessData} = this.state

    if (booleanFields.includes(name)) {
      customAccessData[name] = checked
    } else if (stringFields.includes(name)) {
      customAccessData[name] = value
    } else if (value.length == 0) {
      customAccessData[name] = []
    } else {
      if (name == 'division') {
        value = quickFixAllBoysAllGirls(value)
      }
      if (['brand','division','category','subCategory'].includes(name)) {
        value = value.sort()
      } else if (name == 'status') {
        value = sortStatus(value)
      }

      customAccessData[name] = value
    }

    this.setState({customAccessData})
  }

  saveCustomAccess = () => {
    let {customAccessData, currentUser} = this.state
    saveCustomAccessData(customAccessData, currentUser.id, (err, res) => {
      if (err) {
        alert('There was an error when trying to save custom access data')
      } else {
        this.closeCustomAccessModal()
      }
    })
  }

  closeCustomAccessModal = () =>
  {
    this.setState({ customAccessModal: false, currentUser: null, customAccessData: null, notes: [], users: [], active: false})
  }

  setCustomAccess = (e, user) =>
  {
    e.preventDefault()
    e.stopPropagation()
    var { companies, currentCompanyId } = this.state
    if (user && user.id)
    {
      var company     = companies.find(comp => comp.id === currentCompanyId)
      var currentUser = company.users.find(u => u.id === user.id)

      this.setState({ customAccessModal: true, currentUser, customer: company })

      getCustomAccessData(user.id, (err, res) => {
        if (err) {
          alert('There was an error when trying to get custom access data')
        } else {
          let customAccessData = {}, notes = [], users = []
          if (res.data != null && res.data.customAccess != null) {
            let {customAccess} = res.data
            customAccessData = customAccess.data
            notes = res.data.notes
            users = res.data.users
          }
          this.setState({customAccessData, notes, users})
        }
      })
    }
  }

  fetchQuickbooksAccountNames = () => {
    getQuickbooksAccountNames((err, res) => {
      if (err) {
        alert('There was an error when trying to get quickbooks account names')
      } else {
        let quickbooksAccountNameOptions = sortMapOptions(res.data.salesHistoryAccounts)
        this.setState({quickbooksAccountNameOptions})
      }
    })
  }

  toggleAccordion = () => {
    this.setState({active: !this.state.active})
  }

  render()
  {
    var {companies, currentCompanyId, correctPin} = this.state
    var _user = this.context.user

    if (!correctPin) {
      return null
    }

    var statusColors =
    {
      'active'  : 'green',
      'inactive': 'red',
      'invited' : 'yellow'
    }

    var statusButtonStates =
    {
      'active' :
      {
        content: 'Deactivate',
        color  : 'red'
      },
      'inactive':
      {
        content: 'Activate',
        color  : 'green'
      }
    }

    var markup =
      <div id='companies-section' style={{padding: '50px 60px'}}>
        {
          <div>
            {
              _user && _user.role.toLowerCase() === 'superadmin' && !this.state.loading ?
              <Message className='company-stats'>
                <Message.Header>
                  <h1 style={{marginTop: '10px', marginBottom: '15px'}}>{companies.length} organization{companies.length == 1 ? '' : 's'} found</h1>
                </Message.Header>

                <Button
                  floated='right'
                  icon
                  labelPosition='left'
                  primary
                  size='small'
                  onClick={this.openNewCompanyForm}
                >
                  <Icon name='building outline' /> New organization
                </Button>
              </Message>
              :
              null
            }
            <Accordion fluid styled className="heavyShadow">
              {
                companies.map((company, i) => {
                  let headings =
                  [
                    {
                      key         : "name",
                      displayName : "NAME",
                      sortable    : false,
                    },
                    {
                      key         : "email",
                      displayName : "EMAIL",
                      sortable    : false,
                    },
                    {
                      key         : "role",
                      displayName : "ROLE",
                      sortable    : false,
                    },
                    {
                      key         : "status",
                      displayName : "STATUS",
                      sortable    : false,
                    },
                    {
                      key         : "edit",
                      displayName : "BASIC INFO",
                      sortable    : false,
                    },
                    {
                      key         : "custom_access",
                      displayName : "CUSTOM ACCESS",
                      sortable    : false,
                    },
                    {
                      key         : "last_updated",
                      displayName : "LAST UPDATED ▾",
                      sortable    : false,
                    },
                    {
                      key         : "delete",
                      displayName : "DELETE",
                      sortable    : false,
                    }
                  ]

                  let rows = company.users.length > 0 ? company.users.map((user, i) =>
                  {
                    let cells =
                    {
                      "name":
                      {
                        value        : user.name,
                        displayCell  : <Table.Cell key={`name-${i}`}>{user.name}</Table.Cell>
                      },
                      "email":
                      {
                        value        : user.email,
                        displayCell  : <Table.Cell key={`email-${i}`}>{user.email}</Table.Cell>
                      },
                      "role":
                      {
                        value        : user.role.toUpperCase().replace(/_/g, ' '),
                        displayCell  : <Table.Cell key={`role-${i}`}>{user.role.toUpperCase().replace(/_/g, ' ')}</Table.Cell>
                      },
                      "status":
                      {
                        value        : user.status,
                        displayCell  : <Table.Cell key={`status-${i}`}>
                          <Label
                          color={statusColors[user.status]}
                          size='tiny'
                          content={user.status == 'active' ? 'ACTIVE' : 'BLOCKED'}
                          />
                        </Table.Cell>
                      },
                      "last_updated":
                      {
                        value: user.updatedAt,
                        displayCell: <Table.Cell key={`last_updated-${i}`}>{Utils.formatSimpleDate(user.updatedAt)}</Table.Cell>
                      },
                      "custom_access":
                      {
                        value        : '-',
                        displayCell  : <Table.Cell key={`custom_access-${i}`} collapsing textAlign='center'>
                          <Button fluid
                          icon='key'
                          primary
                          size='mini'
                          onClick={(e) => this.setCustomAccess(e, user)}
                          />
                        </Table.Cell>
                      },
                      "edit":
                      {
                        value        : '-',
                        displayCell  : <Table.Cell key={`edit-${i}`} collapsing textAlign='center'>
                          <Button fluid
                          icon='pencil alternate'
                          secondary
                          size='mini'
                          basic
                          onClick={(e) => this.onSelectUser(e, user)}
                          />
                        </Table.Cell>
                      },
                      "delete":
                      {
                        value        : '-',
                        displayCell  : <Table.Cell key={`delete-${i}`} collapsing textAlign='center'>
                          <Button
                          icon='trash alternate'
                          color='red'
                          size='mini'
                          disabled={_user.id === user.id}
                          onClick={(e) => this.destroyUser(e, company, user)}
                          />
                        </Table.Cell>
                      }
                    }

                    return cells

                  }) : []


                  return <div key={i}>
                    <Accordion.Title
                      className="accordion-header"
                      active={currentCompanyId === company.id}
                      onClick={(e) => this.activateCompanyTab(e, company.id)}
                      >
                      <span>
                        <Icon name='dropdown' />
                        {company.name}
                        <Label style={{marginLeft: '10px'}}>
                          <Icon name='users' />
                          {company.users.length} User{company.users.length == 1 ? '' : 's'}
                        </Label>
                        <Label
                          color={statusColors[company.status]}
                          content={company.status.toUpperCase()}
                          style={{display: 'none'}}
                        />
                        {
                          _user && _user.role.toLowerCase() === 'admin' &&
                          <GoogleIntegration company={company} />
                        }
                      </span>
                      {
                        currentCompanyId === company.id &&
                        <span>
                          {
                            _user && _user.role.toLowerCase() === 'superadmin' && company.status === 'inactive' &&
                            <Button
                              size='mini'
                              color='red'
                              content='Delete organization'
                              onClick={(e) => this.deleteOrganization(e, company)}
                              style={{marginRight: '20px'}}
                            />
                          }
                          {
                            _user && _user.role.toLowerCase() === 'superadmin' &&
                            <Button
                              size='mini'
                              color={statusButtonStates[company.status].color}
                              content={statusButtonStates[company.status].content}
                              onClick={(e) => this.changeCompanyStatus(e, company)}
                              style={{marginRight: '20px'}}
                            />
                          }

                          <Button
                            icon
                            labelPosition='left'
                            primary
                            size='small'
                            onClick={this.openNewUserForm}
                            style={{marginRight: '10px'}}
                          >
                            <Icon name='user' /> New User
                          </Button>

                          <Popup
                            content='Manage organization lists'
                            position='top right'
                            size='small'
                            trigger={<Button secondary basic icon size='small' onClick={(e) => this.openSettingsModal(e, company)}>
                                      <Icon name='list' /> Lists
                                    </Button>}
                          />
                        </span>
                      }
                    </Accordion.Title>
                    <Accordion.Content active={currentCompanyId === company.id}>
                      {
                        company.users.length > 0 ?
                          <SimpleTable
                            rows={rows}
                            headings={headings}
                            selectable={true}
                            currentSortColumn={null}
                          />
                        :
                        <Message className='empty-list-row'>
                          <Message.Header>
                            This organization has no users yet!
                          </Message.Header>
                          <Button
                            floated='right'
                            icon
                            labelPosition='left'
                            primary
                            size='small'
                            onClick={this.openNewUserForm}
                          >
                            <Icon name='user' /> Add user to organization
                          </Button>
                        </Message>
                      }
                    </Accordion.Content>
                  </div>
                })
              }
            </Accordion>

            {customAccessModal(this.state.customAccessModal, this.closeCustomAccessModal, this.state.currentUser, this.saveCustomAccess, this.state.customAccessData, this.state.notes, this.state.users, this.customAccessFieldOnChange, this.state.customer, this.state.quickbooksAccountNameOptions, this.toggleAccordion, this.state.active)}

            <Modal
              open={this.state.newUserModal}
              onClose={this.closeNewUserForm}
              centered={false}
            >
              <Modal.Header>
                {this.state.currentUser ? 'Edit Basic Info' : 'New User'}
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <UserForm
                    user={this.state.currentUser}
                    companyUsers={this.state.companyUsers}
                    companyId={currentCompanyId}
                    closeNewUserForm={this.closeNewUserForm}
                  />
                </Modal.Description>
              </Modal.Content>
            </Modal>

            <Modal
              open={this.state.newCompanyModal}
              onClose={this.closeNewCompanyForm}
              centered={false}
            >
              <Modal.Header>
                {this.state.currentUser ? 'Update Organization Info' : 'New Organization'}
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <CompanyForm
                    closeNewCompanyForm={this.closeNewCompanyForm}
                  />
                </Modal.Description>
              </Modal.Content>
            </Modal>

            <Modal
              open={this.state.settingsModal}
              onClose={this.closeSettingsModal}
              centered={false}
            >
              <Modal.Header>
                Manage organization lists
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <CompanySettings
                    company={this.state.currentCompany}
                    onClose={this.closeSettingsModal}
                  />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color='green'
                  onClick={this.closeSettingsModal}
                  >
                  <Icon name='checkmark' /> Done
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        }
      </div>

    return markup;
  }
}

export default Organizations;
