import React, {Component} from 'react'
import {Button, Loader, Modal, Segment, ListItem, List, Icon, ListContent, Image, Grid, GridRow, GridColumn, Popup} from 'semantic-ui-react'
import {getBestSellers} from './get'
import {formatDate} from '../cads/format-date'
import {updateReactState} from './update-react-state'
import './style.css'
import BestSellersAddForm from './add-form'
import {updateBestSellers} from './update'

const pendingStyle = {
  marginTop: '3px',
  marginRight: '10px',
  display: 'inline-block',
  opacity: 0.3
}

const pendingElement = (
  <Popup content='You still need to click "Save changes" !' trigger={
    <i style={pendingStyle}>pending</i>
  }/>
)

class BestSellersForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      bestSellers: null,
      averageSellers: null,
      poorSellers: null,
      deleteItems: [],
      addItems: [],
      showAddForm: false,
      addType: null
    }
  }

  componentDidMount() {
    this.getBestSellers()
  }

  getBestSellers() {
    let {cad} = this.props

    getBestSellers(cad.id, (err, res) => {
      if (err) {
        alert('There was an error when trying to get best sellers info.')
      } else {
        let {bestSellers, averageSellers, poorSellers} = res.data
        this.setState({loading: false, bestSellers, averageSellers, poorSellers})
      }
    })
  }

  saveBestSellers = () => {
    let {cad} = this.props
    let {deleteItems, addItems} = this.state

    updateBestSellers(cad.id, deleteItems, addItems, (err, res) => {
      if (err) {
        alert('There was an error when trying to update best sellers info.')
      } else {
        let {bestSellerTotal, averageSellerTotal, poorSellerTotal} = res.data
        updateReactState(cad.id, bestSellerTotal, averageSellerTotal, poorSellerTotal)
        this.props.closeModal()
      }
    })
  }

  deleteBestSeller(deleteItem) {
    let {bestSellers, averageSellers, poorSellers, deleteItems} = this.state

    bestSellers = bestSellers.filter(item => item != deleteItem)
    averageSellers = averageSellers.filter(item => item != deleteItem)
    poorSellers = poorSellers.filter(item => item != deleteItem)
    deleteItems.push(deleteItem)

    this.setState({bestSellers, averageSellers, poorSellers, deleteItems})
  }

  showAddBestSeller = () => {
    this.setState({showAddForm: true, addType: 'best'})
  }

  showAddAverageSeller = () => {
    this.setState({showAddForm: true, addType: 'average'})
  }

  showAddPoorSeller = () => {
    this.setState({showAddForm: true, addType: 'poor'})
  }

  goBack = () => {
    this.setState({showAddForm: false, addType: null})
  }

  addBestSeller = (date, contact) => {
    let {addType, addItems, bestSellers, averageSellers, poorSellers} = this.state
    let item = {salesDate: date, accountName: contact, best_or_poor: addType, pending: true}
    addItems.push(item)
    if (addType == 'best') {
      bestSellers.push(item)
      bestSellers = bestSellers.sort((a, b) => a['salesDate'] > b['salesDate'] ? 1 : -1)
    } else if (addType == 'average') {
      averageSellers.push(item)
      averageSellers = averageSellers.sort((a, b) => a['salesDate'] > b['salesDate'] ? 1 : -1)
    } else {
      poorSellers.push(item)
      poorSellers = poorSellers.sort((a, b) => a['salesDate'] > b['salesDate'] ? 1 : -1)
    }
    this.setState({addItems, bestSellers, averageSellers, poorSellers})
    this.goBack()
  }

  render() {
    let {closeModal, cad} = this.props
    let {loading, bestSellers, averageSellers, poorSellers, deleteItems, showAddForm, addType, addItems} = this.state

    let imageUrl
    let s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
    if (cad.filename == null) {
      imageUrl = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png'
    } else if (cad.have_thumb) {
      imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad_thumb/' + cad.filename.split('/')[0] + '/thumb.jpg'
    } else {
      imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename
    }

    return (
      <React.Fragment>
        <Modal.Content>
          <Grid>
            <GridRow>
              <GridColumn width={12}>
                {
                  showAddForm
                  ?
                  <BestSellersAddForm addType={addType} goBack={this.goBack} salesHistoryAccounts={this.props.salesHistoryAccounts} addBestSeller={this.addBestSeller}/>
                  :
                  loading
                  ?
                  <center style={{padding: '10vh'}}>
                    <span className='bounce' style={{display: 'inline-block', fontSize: '20px'}}>⏳</span>
                  </center>
                  :
                  <div>
                    <b style={{borderBottom: '1px solid black'}}>BEST</b>
                    <List divided verticalAlign='middle'>
                      {bestSellers.map(item =>
                        <ListItem>
                          <ListContent floated='right'>
                            {
                              item.pending
                              ?
                              pendingElement
                              :
                              <Button color='red' size='mini' onClick={() => this.deleteBestSeller(item)}>Delete</Button>
                            }
                          </ListContent>
                          <div style={{height: '5px'}}/>
                          <Icon name='thumbs up'/>
                          <ListContent>{item.accountName} — {formatDate(item.salesDate)}</ListContent>
                        </ListItem>
                      )}

                      {
                        bestSellers.length == 0
                        ?
                        <center>No best sellers yet<br/><br/></center>
                        :
                        null
                      }
                    </List>

                    <b style={{borderBottom: '1px solid black'}}>AVERAGE</b>
                    <List divided verticalAlign='middle'>
                      {averageSellers.map(item =>
                        <ListItem>
                          <ListContent floated='right'>
                            {
                              item.pending
                              ?
                              pendingElement
                              :
                              <Button color='red' size='mini' onClick={() => this.deleteBestSeller(item)}>Delete</Button>
                            }
                          </ListContent>
                          <div style={{height: '5px'}}/>
                          <Icon name='smile outline'/>
                          <ListContent>{item.accountName} — {formatDate(item.salesDate)}</ListContent>
                        </ListItem>
                      )}

                      {
                        averageSellers.length == 0
                        ?
                        <center>No average sellers yet<br/><br/></center>
                        :
                        null
                      }
                    </List>

                    <b style={{borderBottom: '1px solid black'}}>POOR</b>
                    <List divided verticalAlign='middle'>
                      {poorSellers.map(item =>
                        <ListItem>
                          <ListContent floated='right'>
                            {
                              item.pending
                              ?
                              pendingElement
                              :
                              <Button color='red' size='mini' onClick={() => this.deleteBestSeller(item)}>Delete</Button>
                            }
                          </ListContent>
                          <div style={{height: '5px'}}/>
                          <Icon name='thumbs down'/>
                          <ListContent>{item.accountName} — {formatDate(item.salesDate)}</ListContent>
                        </ListItem>
                      )}

                      {
                        poorSellers.length == 0
                        ?
                        <center>No poor sellers yet<br/><br/></center>
                        :
                        null
                      }
                    </List>
                  </div>
                }
              </GridColumn>
              <GridColumn width={4}>
                <img src={imageUrl}/>
                {
                  showAddForm
                  ?
                  null
                  :
                  <div id="best-seller-modal-buttons">
                    <Popup content='Add best seller' trigger={
                      <Button primary onClick={this.showAddBestSeller}><Icon name='thumbs up' style={{marginRight: '-3px'}}/></Button>
                    }/>
                    <Popup content='Add average seller' trigger={
                      <Button primary onClick={this.showAddAverageSeller}><Icon name='smile outline' style={{marginRight: '-3px'}}/></Button>
                    }/>
                    <Popup content='Add poor seller' trigger={
                      <Button primary onClick={this.showAddPoorSeller}><Icon name='thumbs down' style={{marginRight: '-3px'}}/></Button>
                    }/>
                  </div>
                }
              </GridColumn>
            </GridRow>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={closeModal}>
            Cancel
          </Button>
          <Button
            content="Save changes"
            labelPosition='right'
            icon='checkmark'
            onClick={this.saveBestSellers}
            disabled={(deleteItems.length == 0 && addItems.length == 0) || showAddForm }
            positive
          />
        </Modal.Actions>
      </React.Fragment>
    )
  }
}

export default BestSellersForm
